/** --------------------------------------------------
*
*   UploadForm
*
*   TABLE OF CONTENTS
*   template
*   tips
*   upload form
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/base/utils";


/** --------------------------------------------------
*   #template
*   -------------------------------------------------- */

.templateLink {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 0.5rem;
  padding: 3rem;
  background-color: vars.$color-kournikova;
  border-radius: 1rem 1rem 0 0;

  &:hover,
  &:focus {
    text-decoration: none;

    .templateIcon {
      animation: download 0.5s linear;
    }

    @keyframes download {
      0% {
        transform: translate(0, 0);
      }

      33% {
        transform: translate(0, -0.35rem);
      }

      100% {
        transform: translate(0, 0);
      }
    }
  }

}

.templateDownload {
  flex: 0 1 calc(100% - 7.3rem);
  min-width: 0;

  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.templateDownloadIcon {
  flex: 0 1 5.8rem;
  min-width: 0;

  padding-right: 3rem;

  path {
    fill: vars.$color-blue-zodiac;
  }

  rect {
    fill: vars.$color-white;
  }
}

.templateDownloadLabel {
  flex: 0 1 calc(100% - 5.8rem);
  min-width: 0;

  font-size: 1.8rem;
  font-weight: 600;
}

.templateIcon {
  flex: 0 0 3.3rem;
  min-width: 0;
}




/** --------------------------------------------------
*   #tips
*   -------------------------------------------------- */

.tips {
  margin-bottom: 0.5rem;
  padding: 3rem;
  background-color: vars.$color-black-squeeze;
}

.tipsTitle {
  margin-bottom: 1rem;
  font-weight: bold;
}

.tipsList {
  list-style: none;
  margin-bottom: 2rem;
}

.tipsList li {
  display: flex;
  align-items: baseline;
  font-size: 1.4rem;

  & svg {
    width: 1.1rem;
    height: auto;
    min-width: 0;
    flex: 0 0 auto;
    margin-right: 1.2rem;
    fill: vars.$color-curious-blue;
  }
}

.tipsList li + li {
  margin-top: 1.2rem;
}

.tipContent {
  white-space: pre-line;
}


/** --------------------------------------------------
*   #upload form
*   -------------------------------------------------- */

.uploadFormContainer {
  margin-bottom: 2rem;
  padding: 2rem 3rem;
  background-color: vars.$color-black-squeeze;
  border-radius: 0 0 1rem 1rem;
}

.uploadForm {
  display: flex;
  flex-flow: row wrap;
}

.uploadFormItem {
  flex: 0 1 70%;
  min-width: 0;
  padding-right: 1.5rem;

  display: flex;
  flex-flow: column nowrap;

  @include utils.mq(max s) {
    flex-basis: 100%;

    margin-bottom: 1rem;
    padding-right: 0;
  }

  input {
    width: 100%;
    flex: 1 1 auto;
    padding: 1.1rem;
    background-color: vars.$color-white;
  }
}

.uploadFormAction {
  width: 100%;

  .uploadFormItem + & {
    flex: 0 1 30%;
    min-width: 0;
  }

  button {
    width: 100%;
  }

  @include utils.mq(max s) {
    flex-basis: 100%;
  }
}