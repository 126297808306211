/** --------------------------------------------------
*
*   base
*   HEADINGS
*
*   TABLE OF CONTENTS
*   page title
*
*   -------------------------------------------------- */


@use "../variables" as vars;
@use "../mixins/text";
@use "../base/utils";


/** --------------------------------------------------
*   #page title
*   -------------------------------------------------- */

.page-title {
  @include text.pageTitle();
}
