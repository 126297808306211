/** --------------------------------------------------
*
*   base - mixins - cta
*   BUTTON SKELETON
*
*   TABLE OF CONTENTS
*   button skeleton
*
*  -------------------------------------------------- */


@use "../../variables" as vars;
@use "../../base/utils";


/** --------------------------------------------------
*  #button skeleton
*  -------------------------------------------------- */

@mixin buttonSkeleton() {
  position: static;
  display: inline-block;
  max-width: 100%;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font-family: vars.$font-basic;
  white-space: normal;
  text-decoration: none;
  text-align: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
