/** --------------------------------------------------
*
*   variables
*   FONTS
*
*   TABLE OF CONTENTS
*   base fonts
*
*   -------------------------------------------------- */




/** --------------------------------------------------
*   #base fonts
*   -------------------------------------------------- */

$_font-montserrat: 'Montserrat', 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;

$font-basic: $_font-montserrat;
$font-heading: $_font-montserrat;
