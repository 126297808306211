/** --------------------------------------------------
*
*   mixins - layout
*   CONTENT WRAPPER
*
*   TABLE OF CONTENTS
*   content width
*
*  -------------------------------------------------- */


@use "../../variables" as vars;
@use "content-width" as content-width;


/** --------------------------------------------------
*  #content width
*  -------------------------------------------------- */

@mixin contentWrapper() {
  @include content-width.contentWidth();

  margin-top: vars.$content-spacing-vertical;
  margin-bottom: vars.$content-spacing-vertical;
}

@mixin contentWrapperInner() {
  max-width: vars.$inner-width;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
