/** --------------------------------------------------
*
*   PermissionEditor
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/base/utils";
@use "~/src/styles/mixins/cta";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.permissions {
  display: flex;
  flex-flow: row wrap;

  margin-bottom: 2rem;

  @include utils.mq(max l) {
    flex-direction: column;
  }
}

.permissionsChoice {
  display: flex;
  flex-flow: row wrap;

  flex: 0 1 calc(100% - 4.6rem);
  width: calc(100% - 4.6rem);

  @include utils.mq(max l) {
    flex-direction: column;

    flex-basis: 100%;
    width: 100%;
  }
}

.permissionEstablishment {
  flex-basis: 70%;
  width: 70%;

  padding-right: 2rem;

  @include utils.mq(max l) {
    flex-basis: 100%;
    width: 100%;

    margin-bottom: 1.5rem;
    padding-right: 0;
  }
}

.permissionRole {
  flex-basis: 30%;
  width: 30%;

  display: flex;

  &>* {
    flex: 1 1 auto;

    & div,
    & select {
      height: 100%;
    }
  }

  @include utils.mq(max l) {
    flex-basis: 100%;
    width: 100%;
  }
}

.removeOrganizationButton {
  @include cta.buttonSkeleton();
  padding: 1.5rem;
  color: vars.$color-brand;

  svg {
    width: 1.6rem;
    height: 1.8rem;
  }
}
