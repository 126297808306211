/** --------------------------------------------------
*
*   base - mixins - link
*   LINK BASIC
*
*   TABLE OF CONTENTS
*   general
*
*  -------------------------------------------------- */


@use "../../variables" as vars;


/** --------------------------------------------------
*  #general
*  -------------------------------------------------- */

@mixin linkBasic() {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  padding: 0;

  color: inherit;
  background-color: transparent;
  border: none;
  font-weight: inherit;
  text-decoration: none;
  text-align: left;
  cursor: pointer;

  transition: color 0.15s ease-in;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

