/** --------------------------------------------------
*
*   EstablishmentMapIcon
*
*   TABLE OF CONTENTS
*   global
*   marker
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;


/** --------------------------------------------------
*   #global
*   -------------------------------------------------- */

:global leaflet-marker-icon {
  position: relative;
}


/** --------------------------------------------------
*   #marker
*   -------------------------------------------------- */

.MapMarker {
  background-color: rgba(vars.$color-kournikova, 0.3);
  width: 4.7rem;
  height: 4.7rem;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &::before {
    content: '';
    background-color: rgba(vars.$color-kournikova, 1);
    width: 2.7rem;
    height: 2.7rem;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50%), calc(-50% - 0.1rem));
  }
}