/** --------------------------------------------------
*
*   HorizontalStackedBarChart
*
*   TABLE OF CONTENTS
*   distance chart
*   distance chart: graphic
*   distance chart: legend
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/cta";
@use "~/src/styles/mixins/layout";
@use "~/src/styles/mixins/text";
@use "~/src/styles/base/utils";


/** --------------------------------------------------
*   #distance chart
*   -------------------------------------------------- */

.distanceChart {}

.figure {}




/** --------------------------------------------------
*   #distance chart: graphic
*   -------------------------------------------------- */

.figureGraphicBlock {}

.figureInner {}

.figureTableWrapper {
  overflow-x: auto;
}

.figureTable {
  width: 100%;
  table-layout: auto;
  border-collapse: separate;
  border-spacing: 0 1.4rem;

  & tbody td {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 1rem;

    &:first-child,
    &:nth-child(2) {
      background-color: transparent;
    }
  }

  & th:first-child,
  & td:first-child {
    white-space: nowrap;
    padding-right: 1.2rem;
    text-align: right;
  }

  & th:first-child {
    font-size: 1.8rem;
    font-weight: 600;
  }

  & td:nth-child(2) {
    white-space: nowrap;
    padding-right: 1.2rem;
  }

  & td:last-child {
    width: 100%;
  }
}

/* number circle */
/* ************* */

.figureNumberCircle {
  position: relative;
  z-index: 1;
  display: block;
  text-align: center;
  min-width: 3.4rem;

  &::after {
    content: '';
    display: block;
    width: 3.4rem;
    height: 3.4rem;
    background-color: vars.$color-kournikova;
    border-radius: 50%;
    position: absolute;
    z-index: -1;
    top: 50%;
    margin-top: -1.7rem;
    left: 50%;
    margin-left: -1.7rem;
  }
}

/* figure bar */
/* ********** */

.figureBar {
  display: flex;
  flex-flow: row nowrap;
  min-width: 40rem;
  border-radius: 1rem;
  overflow: hidden;
}

.figureBarItem {
  position: relative;
  flex: 1 1 auto;
  min-width: 0;
  overflow: hidden;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  background-color: vars.$color-black;
  height: 3rem;
  color: white;

  /* borders for high contrast displays: */
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0.1rem solid transparent;
  }
}

.figureBarItemLabel {
  // visually hidden:
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;

  // not hidden when user has changed colors/contrast:

  @include utils.mqset(highcontrast) {
    clip: initial;
    clip-path: none;
    height: auto;
    overflow: visible;
    position: relative;
    width: auto;
    font-size: 1.2rem;
    margin-right: 0.2em;
    white-space: normal;
  }

  @include utils.mqset(forcedcolors-more) {
    clip: initial;
    clip-path: none;
    height: auto;
    overflow: visible;
    position: relative;
    width: auto;
    font-size: 1.2rem;
    margin-right: 0.2em;
    white-space: normal;
  }
}

.figureBarItemNumber {
  display: inline-block;
  font-size: 1.8rem;
  font-weight: 700;
  text-align: center;
  text-shadow: 0 0 2px vars.$color-black, 0 0 0.5rem rgba(0, 0, 0, 0.2);
}

/* percentage bar */
/* ************** */

.percentageBar {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  list-style: none;
}

.percentageBarItem {
  position: relative;
  flex: 0 0 auto;
  min-width: 0;
  width: 20%;
  font-size: 1.8rem;
  font-weight: 600;
  height: 1.8rem;

  @include utils.mq(max s) {
    font-size: 1.4rem;
  }
}

.percentageBarItemPercentage {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-50%)
}

.percentageBarItem:first-child .percentageBarItemPercentage {
  transform: none;
}

.percentageBarItemFinal {
  position: absolute;
  top: 0;
  right: 0;
  text-align: right;

  & .percentageBarItemPercentage {
    position: static;
    transform: none;
  }
}




/** --------------------------------------------------
*   #distance chart: legend
*   -------------------------------------------------- */

.figureLegendBlock {
  margin-top: 5rem;

  // hide with forced colors:
  @include utils.mqset(highcontrast) {
    display: none;
  }

  @include utils.mqset(forcedcolors-more) {
    display: none;
  }
}

.figureLegend {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.figureLegendItem {
  flex: 0 0 auto;
  min-width: 0;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &:not(:last-child) {
    margin-right: 3rem;
  }

  &::before {
    content: '';
    display: block;
    background-color: vars.$color-black;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.9rem;
    border: 0.3rem solid vars.$color-black;
    border-radius: 50%;
  }
}

.figureLegendItemLabel {}
