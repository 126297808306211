/** --------------------------------------------------
*
*   PasswordTextField
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "~/src/styles/mixins/cta";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.passwordTextFieldWrapper {
  position: relative;

  :global input[type="password"] {
    padding-right: 6rem;
  }
}

.password_show_hide {
  @include cta.buttonSkeleton();

  position: absolute;
  z-index: 5;
  bottom: 2.8rem;
  right: 2rem;
  transform: translateY(50%);

  svg {
    width: 2.4rem;
    height: auto;
  }
}