/** --------------------------------------------------
*
*   ModalSplitTab
*
*   TABLE OF CONTENTS
*   general
*   header
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/layout";
@use "~/src/styles/mixins/text";
@use "~/src/styles/base/utils";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.container {}




/** --------------------------------------------------
*   #header
*   -------------------------------------------------- */

.header {
  margin-top: -1rem;
  margin-bottom: 4rem;
}

.headerInner {
  @include layout.contentWidth();

  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

/* title block */
/* *********** */

.headerTitleBlock {
  flex: 1 1 auto;
  min-width: 0;
  margin: 1rem 0;
}

.headerTitle {
  @include text.contentTitle();
  padding-right: 2em;
}

/* actions block */
/* ************* */

.headerActionsBlock {
  flex: 1 1 25%;
  min-width: 0;
}




/** --------------------------------------------------
*   #content
*   -------------------------------------------------- */

.content {}

.contentInner {
  @include layout.contentWidth();
}
