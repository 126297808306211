/** --------------------------------------------------
*
*   MapEmployeeFilter
*
*   TABLE OF CONTENTS
*   subsection
*   subsection label
*   transportmode
*
*   -------------------------------------------------- */


@use "sass:map";
@use "~/src/styles/variables" as vars;


/** --------------------------------------------------
*   #subsection
*   -------------------------------------------------- */

.MapFilterLegendSubSection {
  margin: 0 -2rem;
  padding: 1.5rem 2rem;
  border-bottom: 0.2rem solid rgba(vars.$color-blue-zodiac, 0.15);

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
    border-bottom: 0;
  }
}




/** --------------------------------------------------
*   #legend
*   -------------------------------------------------- */

.MapFilterLegendSubSectionLabel {
  display: block;
  margin-bottom: 1rem;
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: 600;
}




/** --------------------------------------------------
*   #transportmode
*   -------------------------------------------------- */

.transportNodeItem {}

.transportNodeItem + .transportNodeItem {
  margin-top: 0.8rem;
}

.transportMode {
  &::before {
    content: '';
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 1rem;
    border: 0.1rem solid transparent;
    border-radius: 50%;
  }
}

@each $key,
$value in vars.$transporttype-colors {
  .transportMode[data-type='#{$key}']::before {
    background-color: map-get($value, main);
  }
}
