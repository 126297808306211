/** --------------------------------------------------
*
*   MapLayerCollectionSelector
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.fieldset {}

.fieldsetLegend {
  margin-bottom: 1rem;
}

.fieldsetLabel {
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: 600;
}

.fieldsetContent {}

.formItem {}

.formItem + .formItem {
  margin-top: 0.8rem;
}
