/** --------------------------------------------------
*
*   base
*   GLOBAL
*
*   TABLE OF CONTENTS
*   html & body
*   page containers
*
*   -------------------------------------------------- */


@use "sass:math";
@use "../variables" as vars;
@use "../base/functions";
@use "../base/utils";
@use "../mixins/layout";


/** --------------------------------------------------
*   #html & body
*   -------------------------------------------------- */

html {
  font-size: percentage(math.div(vars.$root-font-size, vars.$base-font-size));
  /* ios: prevent automatic font scaling in landscape while allowing user zoom: */
  -webkit-text-size-adjust: 100%;
}

body {
  font-family: vars.$font-basic;
  font-size: functions.strip-unit(math.div(vars.$base-font-size, vars.$root-font-size)) * 1em;
  line-height: 1.5;
  background-color: vars.$color-catskill-white;
  color: vars.$color-text;
}




/** --------------------------------------------------
*   #page containers
*   -------------------------------------------------- */

.mobiscan-app {
  display: flex;
  flex-flow: column wrap;

  min-height: 100vh;

  max-width: vars.$site-width;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  box-shadow: 0 0 1rem 0.1rem vars.$color-geyser;
  background-color: vars.$color-white;

  // See footer.module.scss for the margin-top: auto; trick
}

.main-content {
  width: 100%;
}

.content-wrapper {
  @include layout.contentWrapper();
}

.inner-wrapper {
  @include layout.contentWrapperInner();
}
