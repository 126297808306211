/** --------------------------------------------------
*
*   EmployeeCommuneMap
*
*   TABLE OF CONTENTS
*   map
*   marker
*   legend
*   - legend title
*   - legend list item
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/base/utils";


/** --------------------------------------------------
*   #map
*   -------------------------------------------------- */

.container {
  display: flex;
  flex-flow: row wrap;
  gap: 4rem;

  @include utils.mq(max l) {
    gap: 2rem;
  }
}

.mapBlock {
  flex: 1 1 auto;
  min-width: 0;
  display: flex;
  flex-flow: column nowrap;

  @include utils.mq(max m) {
    width: 100%;
  }

  & > * {
    width: 100%;
    flex: 1 1 auto;
  }

  /* leaflet: */
  /* @todo: do this somewhere else (?) */
  & path:hover {
    fill-opacity: 0.95;
  }
}

.legendBlock {
  flex: 0 1 28rem;

  @include utils.mq(max m) {
    width: 100%;
    flex: 0 1 auto;
  }
}




/** --------------------------------------------------
*   #legend
*   -------------------------------------------------- */

.EmployeeCommuneMapMarker {
  background-color: rgba(vars.$color-kournikova, 0.3);
  width: 4.7rem;
  height: 4.7rem;
  margin-bottom: 1rem;
  border: 0.1rem solid transparent;
  border-radius: 50%;
  position: relative;

  &::before {
    content: '';
    background-color: rgba(vars.$color-kournikova, 1);
    width: 2.7rem;
    height: 2.7rem;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50%), calc(-50% - 0.1rem));
  }
}

.EmployeeCommuneMapLegend {
  background-color: vars.$color-white;
  padding: 3rem;
  border: 0.1rem solid vars.$color-blue-zodiac;
  border-radius: 1rem;

  p {
    margin-bottom: 1rem;
    font-weight: 600;
  }
}

/* legend title */

.EmployeeCommuneMapLegendTitle {
  margin: 0.5rem 0 3.4rem;
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: 800;
}


/* legend list item */

.EmployeeCommuneMapLegendListitem {
  display: flex;
  gap: 1rem;
  align-items: center;
  font-size: 1.2rem;
  line-height: 1.5;
  font-weight: 600;

  &:not(:last-child) {
    margin-bottom: 0.6rem;
  }

  &::before {
    content: '';
    background-color: vars.$color-black;
    width: 3.4rem;
    height: 1.4rem;
    border: 0.1rem solid transparent;
    border-radius: 0.5rem;
  }

  &[data-type="50"]::before {
    background-color: #14315C;
  }

  &[data-type="30"]::before {
    background-color: #416083;
  }

  &[data-type="20"]::before {
    background-color: #6F8EAA;
  }

  &[data-type="10"]::before {
    background-color: #9CBDD0;
  }

  &[data-type="1"]::before {
    background-color: #C9EBF7;
  }
}
