/** --------------------------------------------------
*
*   variables
*   COLORS
*
*   TABLE OF CONTENTS
*   named colors
*   base colors
*   text colors
*   background colors
*   cta colors
*   error colors
*
*   -------------------------------------------------- */




/** --------------------------------------------------
*   #named colors
*   -------------------------------------------------- */

$color-blue-zodiac: #14315c;
$color-navy-blue: #0a006f;
$color-matisse: #16779c;
$color-curious-blue: #20b0e4;
$color-iceberg: #d6ebf1;

$color-kournikova: #fed87b;

$color-geyser: #d9dee4;
$color-black-squeeze: #f1f5fa;
$color-catskill-white: #f8fafc;

$color-monza: #e2031a;

$color-cactus: #5a7858;
$color-spring-rain: #a1cca5;




/** --------------------------------------------------
*   #base colors
*   -------------------------------------------------- */

$color-black: #000;
$color-white: #fff;

$color-brand: $color-blue-zodiac;




/** --------------------------------------------------
*   #text colors
*   -------------------------------------------------- */

$color-text: $color-blue-zodiac;
$color-text--bright: $color-navy-blue;
$color-text--light: $color-matisse;




/** --------------------------------------------------
*   #background colors
*   -------------------------------------------------- */

$color-background-light: $color-black-squeeze;




/** --------------------------------------------------
*   #cta colors
*   -------------------------------------------------- */

/* primary */
/* ******* */

$color-cta-background: $color-blue-zodiac;
$color-cta-foreground: $color-white;
$color-cta-border: $color-blue-zodiac;

$color-cta-background--focus: $color-kournikova;
$color-cta-foreground--focus: $color-blue-zodiac;
$color-cta-border--focus: $color-kournikova;

/* secondary */
/* ********* */

$color-cta-background--secondary: $color-kournikova;
$color-cta-foreground--secondary: $color-blue-zodiac;
$color-cta-border--secondary: $color-blue-zodiac;

$color-cta-background--secondary--focus: $color-white;
$color-cta-foreground--secondary--focus: $color-blue-zodiac;
$color-cta-border--secondary--focus: $color-blue-zodiac;

/* primary inverse */
/* *************** */

$color-cta-background--inverse: $color-white;
$color-cta-foreground--inverse: $color-blue-zodiac;
$color-cta-border--inverse: $color-blue-zodiac;

$color-cta-background--focus--inverse: $color-black-squeeze;
$color-cta-foreground--focus--inverse: $color-blue-zodiac;
$color-cta-border--focus--inverse: $color-blue-zodiac;






/** --------------------------------------------------
*   #error colors
*   -------------------------------------------------- */

$color-error-foreground: $color-monza;




/** --------------------------------------------------
*   #input colors
*   -------------------------------------------------- */

$color-input-foreground: $color-blue-zodiac;
$color-input-background: $color-catskill-white;
$color-input-border: $color-blue-zodiac;
$color-input-placeholder: $color-blue-zodiac;




/** --------------------------------------------------
*   #loader colors
*   -------------------------------------------------- */

$color-spinner-foreground: $color-blue-zodiac;
$color-spinner-background: $color-geyser;




/** --------------------------------------------------
*   #transport type colors
*   -------------------------------------------------- */

$transporttypecolor--bike: #00ff00;
$transporttypecolor--bike--complement: #00ff00;
$transporttypecolor--bus_tram: #0000ff;
$transporttypecolor--bus_tram--complement: #0000ff;
$transporttypecolor--carpool: #ff8080;
$transporttypecolor--carpool--complement: #ff8080;
$transporttypecolor--collective_company: #008080;
$transporttypecolor--collective_company--complement: #008080;
$transporttypecolor--company_car: #800080;
$transporttypecolor--company_car--complement: #800080;
$transporttypecolor--e_bike: #339933;
$transporttypecolor--e_bike--complement: #339933;
$transporttypecolor--e_step: #339933;
$transporttypecolor--e_step--complement: #339933;
$transporttypecolor--moped: #ffcc00;
$transporttypecolor--moped--complement: #ffcc00;
$transporttypecolor--motorbike: #ff6600;
$transporttypecolor--motorbike--complement: #ff6600;
$transporttypecolor--pedelec: #336600;
$transporttypecolor--pedelec--complement: #336600;
$transporttypecolor--pedestrian: #c6deb5;
$transporttypecolor--pedestrian--complement: #c6deb5;
$transporttypecolor--train: #00ffff;
$transporttypecolor--train--complement: #00ffff;
$transporttypecolor--personal_car: #ff0000;
$transporttypecolor--personal_car--complement: #ff0000;

$transporttype-colors: (
  'bike': (main: $transporttypecolor--bike,
    complement: $transporttypecolor--bike--complement ),
  'bus_tram': (main: $transporttypecolor--bus_tram,
    complement:$transporttypecolor--bus_tram--complement ),
  'carpool': (main: $transporttypecolor--carpool,
    complement:$transporttypecolor--carpool--complement ),
  'collective_company': (main: $transporttypecolor--collective_company,
    complement:$transporttypecolor--collective_company--complement ),
  'company_car': (main: $transporttypecolor--company_car,
    complement:$transporttypecolor--company_car--complement ),
  'e_bike': (main: $transporttypecolor--e_bike,
    complement:$transporttypecolor--e_bike--complement ),
  'e_step': (main: $transporttypecolor--e_step,
    complement:$transporttypecolor--e_step--complement ),
  'moped': (main: $transporttypecolor--moped,
    complement:$transporttypecolor--moped--complement ),
  'motorbike': (main: $transporttypecolor--motorbike,
    complement:$transporttypecolor--motorbike--complement ),
  'pedelec': (main: $transporttypecolor--pedelec,
    complement:$transporttypecolor--pedelec--complement ),
  'pedestrian': (main: $transporttypecolor--pedestrian,
    complement:$transporttypecolor--pedestrian--complement ),
  'train': (main: $transporttypecolor--train,
    complement:$transporttypecolor--train--complement ),
  'personal_car': (main: $transporttypecolor--personal_car,
    complement:$transporttypecolor--personal_car--complement ),
);
