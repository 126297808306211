/** --------------------------------------------------
*
*   HomePage
*
*   TABLE OF CONTENTS
*   intro
*   intro: user block
*   unconfirmed page
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/cta";
@use "~/src/styles/mixins/text";
@use "~/src/styles/base/utils";
@use "~/src/styles/mixins/layout";


/** --------------------------------------------------
*   #intro
*   -------------------------------------------------- */

.introBlock {
  padding: 2rem 0 16rem;

  @include utils.mq(max l) {
    padding: 0;
  }
}

.introTitle {
  @include text.pageTitle();
  margin-bottom: 2rem;
}

.introDescription {
  font-size: 1.6rem;
  line-height: 1.75;
}

.introList {
  margin: 2rem 0 2rem 3rem;
}

.introLink {
  @include cta.buttonSkeleton();
  text-decoration: underline;

  &:focus,
  &:hover {
    color: vars.$color-iceberg;
  }
}




/** --------------------------------------------------
*   #intro: user block
*   -------------------------------------------------- */

.userBlock {
  width: 100%;
  max-width: 46rem;
  color: vars.$color-text;
  background-color: vars.$color-white;
  border: 0.1rem solid vars.$color-brand;
  border-radius: 1rem;
  overflow: hidden;
  margin-left: auto;

  @include utils.mq(max l) {
    max-width: 100%;
    margin-left: 0;
    margin-bottom: 10rem;
  }
}

/* header */
/* ****** */

.userBlockHeader {}

.userBlockHeaderTop {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 2rem 3.2rem;

  @include utils.mq(max s) {
    padding: 1.4rem 2rem;
  }
}

.userBlockHeaderTopTitle {
  flex: 1 1 auto;
  min-width: 0;
  margin: 0.4rem 0;
  padding-right: 2rem;

  font-size: 1.2rem;
  line-height: 1.4;
  font-weight: bold;
  text-transform: uppercase;
}

.userBlockHeaderTopActions {
  flex: 0 0 auto;
  min-width: 0;
}

.userBlockHeaderLogout {
  @include cta.buttonSkeleton();
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  border: 0.1rem solid vars.$color-brand;
  background-color: vars.$color-background-light;
  border-radius: 0.5rem;
  padding: 0.8rem 1rem;

  font-size: 1.4rem;
  line-height: 1.2;
  font-weight: 600;

  transition: background-color 0.15s ease-out;

  &:hover,
  &:focus {
    background-color: vars.$color-kournikova;
  }

  &:focus,
  &:active {
    text-decoration: underline;
  }

  & > span {
    flex: 1 1 auto;
    min-width: 0;
  }

  & > svg {
    flex: 0 0 auto;
    min-width: 0;
    width: 1.6rem;
    height: 1.6rem;
    margin-left: 0.8rem;
  }
}

.userBlockHeaderContent {
  padding: 2rem 3.2rem;
  display: flex;
  flex-flow: row wrap;

  @include utils.mq(max s) {
    padding: 1.4rem 2rem;
  }
}

.userBlockUserDetails {
  flex: 1 1 auto;
  min-width: 0;
  padding-right: 2rem;

  @include utils.mq(max s) {
    width: 100%;
    padding-right: 0;
  }
}

.userBlockUserDetailsName {
  font-weight: bold;
  font-size: 2rem;
  line-height: 1.2;
}

.userBlockUserDetailsAdditional {
  font-size: 1.4rem;
  line-height: 1.5;
}

.userBlockUserActions {
  flex: 0 1 auto;
  min-width: 0;
}

.userBlockUserAction {
  font-size: 1.4rem;
  line-height: 1.4;
  font-weight: 600;
  text-decoration: underline;
  color: vars.$color-text--light;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

/* actions */
/* ******* */

.userBlockHeader + .userBlockActions {
  border-top: 0.1rem solid vars.$color-brand;
}

.userBlockActions {}

.userBlockAction {}

.userBlockAction + .userBlockAction {
  border-top: 0.1rem solid vars.$color-brand;
}

.userBlockActionLink {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  text-decoration: none;
  transition: background-color 0.15s ease-out;
  padding: 1.4rem 3.2rem;
  font-size: 1.4rem;
  line-height: 1.5;
  font-weight: 600;

  @include utils.mq(max s) {
    padding: 1.4rem 2rem;
  }

  &:hover,
  &:focus {
    background-color: vars.$color-background-light;
  }

  &:focus,
  &:active {
    text-decoration: underline;
  }

  &:focus-visible {
    outline-offset: -0.4rem;
    box-shadow: inset 0 0 0 0.2rem black;
  }

  & > img {
    flex: 0 0 auto;
    min-width: 0;
    display: block;
    width: 3.2rem;
    height: 3.2rem;
    margin-right: 1.8rem;

    @include utils.mq(max xs) {
      width: 2rem;
      height: 2rem;
    }
  }
}

.userBlockActionLinkText {
  flex: 1 1 auto;
  min-width: 0;
  padding-right: 1rem;
  word-break: break-word;
}

.userBlockActionIcon {
  flex: 0 0 auto;
  min-width: 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  color: vars.$color-brand;
  border: 0.2rem solid;
  border-radius: 50%;
  width: 2.6rem;
  height: 2.6rem;
  background-color: vars.$color-background-light;
  transition: background-color 0.15s ease-out;

  & svg {
    display: block;
    width: 1.4rem;
    height: 1.4rem;
  }
}

.userBlockActionLink:hover .userBlockActionIcon,
.userBlockActionLink:focus .userBlockActionIcon {
  background-color: vars.$color-white;
}




/** --------------------------------------------------
*   #unconfirmed page
*   -------------------------------------------------- */

.unconfirmedPage {
  @include layout.contentWrapper();
}

.unconfirmedTitle {
  margin-bottom: 3rem;
}

.unconfirmedContent {
  margin-bottom: 3rem;
}