/** --------------------------------------------------
*
*   TextMarker
*
*   TABLE OF CONTENTS
*   global
*   textmarker
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;


/** --------------------------------------------------
*   #global
*   -------------------------------------------------- */

:global .leaflet-div-icon {
  background: transparent;
  color: inherit;
  border: 0;
}


/** --------------------------------------------------
*   #textmarker
*   -------------------------------------------------- */

.TextMarker {
  background-color: vars.$color-matisse;
  border-radius: 2rem;
  font-size: 1.1rem;
  line-height: 1.5;
  color: vars.$color-white;
  font-family: vars.$font-basic;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}
