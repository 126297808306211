/** --------------------------------------------------
*
*   base - components - navigation
*   PAGINATION
*
*   TABLE OF CONTENTS
*   general
*
*  -------------------------------------------------- */


@use "../../variables" as vars;
@use "../../mixins/cta";


/** --------------------------------------------------
*  #general
*  -------------------------------------------------- */

.pagination {
  margin-top: 2rem;

  font-size: 1.6rem;
  line-height: 1.5;
}

.pagination-list {
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 0 -0.5rem -1rem;
}

.pagination-list li {
  flex: 0 1 auto;
  padding: 0 0.5rem 1rem;
}

.pagination-list a {
  padding: 0.2em 0.4em;
  text-decoration: none;
  border-radius: 0.5rem;
  min-width: 3rem;
  border: 0.2rem solid transparent;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  transition: background-color 0.15s ease-out;

  &:hover,
  &:focus {
    text-decoration: underline;
    background-color: vars.$color-background-light;
  }

  &[aria-current="page"] {
    text-decoration: underline;
    background-color: vars.$color-kournikova;
  }
}
