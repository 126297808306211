/** --------------------------------------------------
*
*   MobilityProfileTab
*
*   TABLE OF CONTENTS
*   intro
*   tabs
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/base/utils";
@use "~/src/styles/mixins/layout";
@use "~/src/styles/mixins/text";


/** --------------------------------------------------
*   #intro
*   -------------------------------------------------- */

.intro {
  margin-top: vars.$content-spacing-vertical;
}

.introInner {
  @include layout.contentWidth();
}

.introTitleHolder {}

.pageTitle {
  @include text.pageTitle();
  display: inline-block;
  margin-right: 2rem;
}

.introTitleAdditional {
  display: inline-block;
  font-size: 2rem;
  line-height: 1.2;
  font-weight: bold;
  color: vars.$color-text--light;

  @include utils.mq(max s) {
    font-size: 1.6rem;
  }
}




/** --------------------------------------------------
*   #tabs
*   -------------------------------------------------- */

.tabsBlock {
  margin-top: 5rem;
}
