/** --------------------------------------------------
*
*   base
*   CTA
*
*   TABLE OF CONTENTS
*   button primary
*   button secondary
*   button info
*
*   -------------------------------------------------- */


@use "../variables" as vars;
@use "../mixins/cta" as cta;
@use "../base/utils";


/** --------------------------------------------------
*   #button primary
*   -------------------------------------------------- */

.btn-primary {
  @include cta.ctaPrimary();
}

.btn-primary--large {
  width: 100%;
}

.btn-primary--add span::before {
  content: '+';
  margin-right: 0.5rem;
}




/** --------------------------------------------------
*   #button secondary
*   -------------------------------------------------- */

.btn-secondary {
  @include cta.ctaSecondary();
}

.btn-secondary--small {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  font-size: 1.4rem;
}

.btn-secondary--add span::before {
  content: '+';
  margin-right: 0.5rem;
}




/** --------------------------------------------------
*   #button info
*   -------------------------------------------------- */

.btn-info {
  @include cta.ctaPrimaryInverse();
}

.btn-info--small {
  padding: 0.3rem 0.8rem;
}
