/** --------------------------------------------------
*
*   base - components - table
*   DATA TABLE
*
*   TABLE OF CONTENTS
*   wrapper
*   table
*   zebra stripe table
*
*  -------------------------------------------------- */


@use "../../variables" as vars;
@use "../../mixins/cta";


/** --------------------------------------------------
*  #wrapper
*  -------------------------------------------------- */

.data-table-wrapper {
  overflow-x: auto;
}




/** --------------------------------------------------
*  #table
*  -------------------------------------------------- */

.data-table {
  position: relative;
  border-collapse: separate;
  border-spacing: 0 0.8rem;

  & tr {
    vertical-align: middle;
  }

  & td,
  & th {
    padding: 1.2rem 1.6rem;
    font-size: 1.2rem;
    line-height: 1.5;
  }

  & thead {
    & th {
      text-align: left;
    }
  }

  & tbody {
    & tr {
      background-color: vars.$color-black-squeeze;

      & td {
        border: 0.1rem solid transparent;

        &:first-child {
          border-radius: 1rem 0 0 1rem;
        }

        &:last-child {
          border-radius: 0 1rem 1rem 0;
        }
      }
    }
  }

  /* alignment options */
  /* ***************** */

  & [data-align="right"] {
    text-align: right;
  }
}




/** --------------------------------------------------
*  #zebra stripe table
*  -------------------------------------------------- */

.data-table.white-zebra {

  & th {
    font-weight: 600;
  }

  & td {
    font-weight: 500;
  }

  & td,
  & th {
    font-size: 1.6rem;
    line-height: 1.75;
  }

  & tbody {
    & tr {
      background-color: vars.$color-white;

      & td {
        border-top: 0.1rem solid vars.$color-iceberg;
        border-bottom: 0.1rem solid vars.$color-iceberg;

        &:first-child {
          border-left: 0.1rem solid vars.$color-iceberg;
          border-radius: 1rem 0 0 1rem;
        }

        &:last-child {
          border-right: 0.1rem solid vars.$color-iceberg;
          border-radius: 0 1rem 1rem 0;
        }
      }
    }
  }
}




/** --------------------------------------------------
*  #table elements
*  -------------------------------------------------- */

/* action button */
/* ************* */

.data-table-action {
  @include cta.ctaSecondary();

  padding: 0.7rem 3rem;
  border: 0;
}
