/** --------------------------------------------------
*
*   Retry
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/base/utils";
@use "~/src/styles/mixins/cta";
@use "~/src/styles/mixins/layout";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.container {
  @include layout.contentWidth();
  margin-top: 3.4rem;
  margin-bottom: 3.4rem;
}

.box {
  padding: 2rem;
  color: vars.$color-error-foreground;
  font-weight: 600;
  border-left: 0.5rem solid;
  border-radius: 0.5rem;
  background-color: vars.$color-background-light;
}




/** --------------------------------------------------
*   #header
*   -------------------------------------------------- */

.header {}

/* title */
/* ***** */

.title {
  font-size: 1.6rem;
  line-height: 1.75;
  font-weight: bold;
}




/** --------------------------------------------------
*   #info
*   -------------------------------------------------- */

.info {
  margin-top: 0.6rem;

  font-size: 1.6rem;
  line-height: 1.75;
  font-weight: normal;
}




/** --------------------------------------------------
*   #actions
*   -------------------------------------------------- */

.actions {
  margin-top: 1.6rem;
}

/* retry button */
/* ************ */

.retryAction {
  @include cta.ctaPrimary();
}
