/** --------------------------------------------------
*
*   SummaryTab - potential
*
*   TABLE OF CONTENTS
*   general
*   horizontal layout
*   summary visualisation: horizontal
*   summary visualisation: vertical
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/layout";
@use "~/src/styles/base/utils";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.potentialBlock {
  position: relative;
}

.potentialBlockInner {
  position: relative;
}

/* graphic block */
/* ************* */

.potentialBlockGraphic {
  width: 100%;
  max-width: 38rem;
  margin: 0 auto 1.6rem;
}

.potentialBlockGraphicImageHolder {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 40%;

  & img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center bottom;
  }
}

/* content block */
/* ************* */

.transportationContent {
  margin-bottom: 5rem;
}

.transportationTitle {
  font-size: 2.5rem;
  line-height: 1.28;
  margin-bottom: 1rem;

  @include utils.mq(max s) {
    font-size: 2rem;
  }
}

.transportationText {
  font-size: 1.6rem;
  line-height: 1.75;

  @include utils.mq(max s) {
    font-size: 1.4rem;
  }
}

/* visualisation block */
/* ******************* */

.transportationVisualisationHolder {
  position: relative;
}




/** --------------------------------------------------
*   #horizontal layout
*   -------------------------------------------------- */

.potentialBlock[data-vertical="false"] {
  margin-top: 5rem;
  margin-bottom: 5rem;

  @include utils.mq(max l) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @include utils.mq(max m) {
    text-align: center;
  }
}

.potentialBlock[data-vertical="false"] .potentialBlockInner {
  @include layout.contentWidth();

  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
}

.potentialBlock[data-vertical="false"] .potentialBlockGraphic {
  flex: 0 1 25%;
  min-width: 0;
  padding-right: 4rem;

  @include utils.mq(max m) {
    flex-basis: 100%;
    padding-right: 0;
  }
}

.potentialBlock[data-vertical="false"] .transportationContent {
  flex: 0 1 36%;
  min-width: 0;
  margin-bottom: 0;
  padding-right: 4rem;

  @include utils.mq(max l) {
    flex-basis: 75%;
    padding-right: 0;
    margin-bottom: 2rem;
  }

  @include utils.mq(max m) {
    flex-basis: 100%;
  }
}

.potentialBlock[data-vertical="false"] .transportationVisualisationHolder {
  flex: 0 1 37%;
  min-width: 0;

  @include utils.mq(max l) {
    flex-basis: 100%;
  }
}




/** --------------------------------------------------
*   #summary visualisation: horizontal
*   -------------------------------------------------- */

.summaryHorizontal {
  position: relative;
  background-color: vars.$color-black-squeeze;
  padding: 2rem;
  border-radius: 15rem;
  max-width: 43.6rem;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  @include utils.mq(max l) {
    margin-right: auto;
    margin-left: auto;
  }

  @include utils.mq(max s) {
    max-width: 100%;
    border-radius: 1rem;
    flex-flow: column wrap;
  }

  // decoration to go between the two circles:
  &::after {
    content: '';
    display: block;
    width: 28%;
    height: 100%;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 35%;
    background-image: url('../../../../../assets/images/decoration_movement-line.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;

    @include utils.mq(max s) {
      display: none;
    }
  }
}

.summaryHorizontalBlock {
  position: relative;
  width: 38%;

  @include utils.mq(max s) {
    width: 100%;
  }
}

.summaryHorizontalBlock + .summaryHorizontalBlock {

  @include utils.mq(max s) {
    padding-top: 1rem;
  }
}

/* circle layout */
/* ************* */

.summaryHorizontalCircle {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  /* keep aspect ratio: */
  &::before {
    content: "";
    float: left;
    padding-bottom: 100%;
  }

  &::after {
    clear: left;
    content: " ";
    display: table;
  }

  @include utils.mq(max s) {

    &::before,
    &::after {
      display: none;
    }
  }
}

// use an absolutely positioned background
// element to prevent elongated circles, but
// allow elongated circles if there's too much
// content:
.summaryHorizontalCircleBackground {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: vars.$color-white;
  border: 0.1rem solid vars.$color-brand;
  border-radius: 50%;

  @include utils.mq(max s) {
    border-radius: 1rem;
  }
}

.summaryHorizontalCircle[data-type="potential"] {
  .summaryHorizontalCircleBackground {
    background-color: vars.$color-kournikova;
  }
}

.summaryHorizontalCircleContent {
  position: relative;
  z-index: 2;
  text-align: center;

  @include utils.mq(max s) {
    padding: 1rem;
  }
}

.summaryHorizontalCircleValue {
  font-weight: bold;
  font-size: 3.6rem;
  line-height: 1;
  padding-top: 0.5rem;

  @include utils.mq(max l) {
    font-size: 2.8rem;
  }

  @include utils.mq(max m) {
    font-size: 2.4rem;
  }
}

.summaryHorizontalCircleDescription {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;

  padding-top: 0.5rem;
  color: vars.$color-matisse;
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 1.28;

  svg {
    width: 2.7rem;
    height: auto;
  }

  @include utils.mq(max m) {
    font-size: 2rem;
  }

  @include utils.mq(max s) {
    svg {
      width: 2rem;
    }
  }
}

.summaryHorizontalCircle[data-type="potential"] {
  .summaryHorizontalCircleDescription {
    color: vars.$color-text;
  }
}




/** --------------------------------------------------
*   #summary visualisation: vertical
*   -------------------------------------------------- */

.summaryVertical {
  margin-top: auto;

  position: relative;
  background-color: vars.$color-white;
  padding: 2rem;
  border-radius: 15rem;
  max-width: 18.6rem;
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: center;

  @include utils.mq(max s) {
    max-width: 100%;
    border-radius: 1rem;
  }

  // decoration to go between the two circles:
  &::after {
    content: '';
    display: block;
    width: 60%;
    height: 100%;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-image: url('../../../../../assets/images/decoration_movement-line--vertical.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;

    @include utils.mq(max s) {
      display: none;
    }
  }
}

.summaryVerticalBlock {
  position: relative;
  width: 100%;

  @include utils.mq(max s) {
    width: 100%;
  }
}

.summaryVerticalBlock + .summaryVerticalBlock {
  padding-top: 60%;

  @include utils.mq(max s) {
    padding-top: 1rem;
  }
}

/* circle layout */
/* ************* */

.summaryVerticalCircle {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  /* keep aspect ratio: */
  &::before {
    content: "";
    float: left;
    padding-bottom: 100%;
  }

  &::after {
    clear: left;
    content: " ";
    display: table;
  }

  @include utils.mq(max s) {

    &::before,
    &::after {
      display: none;
    }
  }
}

// use an absolutely positioned background
// element to prevent elongated circles, but
// allow elongated circles if there's too much
// content:
.summaryVerticalCircleBackground {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: vars.$color-black-squeeze;
  border: 0.1rem solid vars.$color-brand;
  border-radius: 50%;

  @include utils.mq(max s) {
    border-radius: 1rem;
  }
}

.summaryVerticalCircle[data-type="potential"] {
  .summaryVerticalCircleBackground {
    background-color: vars.$color-kournikova;
  }

  .summaryVerticalCircleDescription {
    color: vars.$color-blue-zodiac;
  }
}

.summaryVerticalCircleContent {
  position: relative;
  z-index: 2;
  text-align: center;

  @include utils.mq(max s) {
    padding: 1rem;
  }
}

.summaryVerticalCircleValue {
  font-weight: bold;
  font-size: 3.6rem;
  line-height: 1;
  padding-top: 0.5rem;

  @include utils.mq(max l) {
    font-size: 2.8rem;
  }

  @include utils.mq(max m) {
    font-size: 2.4rem;
  }
}

.summaryVerticalCircleDescription {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;

  padding-top: 0.5rem;
  color: vars.$color-matisse;
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 1.28;

  svg {
    width: 2.7rem;
    height: auto;
  }

  @include utils.mq(max m) {
    font-size: 2rem;
  }

  @include utils.mq(max s) {
    svg {
      width: 2rem;
    }
  }
}
