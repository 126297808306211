/** --------------------------------------------------
*
*   components - forms
*   FORM INPUTS
*
*   TABLE OF CONTENTS
*   general
*   input placeholders
*
*   -------------------------------------------------- */


@use "../../variables" as vars;
@use "../../base/utils";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="date"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="tel"],
input[type="url"],
input[type="search"],
input[type="file"],
textarea {
  max-width: 100%;
  appearance: none;

  padding: 1.5rem 2.6rem;
  background-color: vars.$color-input-background;
  border: 0.1rem solid vars.$color-input-border;
  border-radius: 1rem;
  box-shadow: none;

  font-family: vars.$font-basic;
  font-size: 1.6rem;
  line-height: 1.5;
  color: vars.$color-input-foreground;
  transition: border-color 0.15s ease-out;

  &:focus {
    border-color: vars.$color-input-border;
    box-shadow: 0 0 0 0.1rem black;
    outline: 0.2rem solid transparent;
    outline-offset: 0.2rem;
  }

  &[disabled] {
    opacity: 0.5;
  }
}

/* textarea */
/* ******** */

textarea {
  resize: vertical;
  height: auto;
  min-height: 16rem;
  /* prevent white-space at the bottom of the textarea: */
  vertical-align: top;
}




/** --------------------------------------------------
*   #input placeholders
*   -------------------------------------------------- */

::-webkit-input-placeholder {
  color: var(--color-input-placeholder);
  font-style: italic;
}

::-moz-placeholder {
  color: var(--color-input-placeholder);
  font-style: italic;
}

:-ms-input-placeholder {
  color: var(--color-input-placeholder);
  font-style: italic;
}

:-moz-placeholder {
  color: var(--color-input-placeholder);
  font-style: italic;
}
