/** --------------------------------------------------
*
*   CorrectionTable
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/base/utils";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.timetableContainer {
  overflow-x: auto;
}

.timetable {
  border-collapse: collapse;
  border-spacing: 0;

  tr {
    vertical-align: middle;
  }

  td,
  th {
    padding: 1.5rem;
    font-size: 1.4rem;

    span {
      display: none;
    }
  }

  thead {

    th {
      text-align: left;
    }
  }

  tbody {

    tr {
      border-bottom: 0.5rem solid vars.$color-white;

      &:last-child {
        border-bottom: none;
      }
    }

    td {
      background-color: vars.$color-black-squeeze;
    }

    select {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      min-width: 10rem;
    }
  }
}
