/** --------------------------------------------------
*
*   RadioButton
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/base/utils";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.radioButton {
  position: relative;
  min-height: 3rem;

  /* input */
  /* ***** */

  /*
   * the actual radiobutton input is hidden
   * -> do not use "display: none" or
   *    "visibility: hidden" to hide the element,
   *    because that will make it inaccessible
   *    for keyboard users
   */

  &>input {
    appearance: none;
    opacity: 0;
    position: absolute;
    top: 50%;
    margin-top: -1.2rem;
    left: 0;
    width: 2.4rem;
    height: 2.4rem;
    background: transparent;
    border: none;
  }

  /* label */
  /* ***** */

  &>label {
    display: inline-block;
    position: relative;
    margin: 0;
    padding-left: 4rem;
    cursor: pointer;

    /*
     * pseudo-elements for the radio button container and the
     * checked radio button
     */

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
    }

    &:before {
      top: 50%;
      margin-top: -1.2rem;
      left: 0;
      width: 2.4rem;
      height: 2.4rem;
      border: 0.1rem solid vars.$color-blue-zodiac;
      border-radius: 50%;
      background-color: vars.$color-catskill-white;
      transition: border-color 0.2s ease;
    }

    &:after {
      width: 0;
      height: 0;
      top: 50%;
      margin-top: -0.6rem;
      left: 0.6rem;
      border: 0.6rem solid vars.$color-catskill-white;
      opacity: 0;
      visibility: hidden;
      border-radius: 50%;
      transition: opacity 0.2s ease, visibility 0.2s ease, transform 0.2s ease;
      transform: scale(0.7);
    }

    /* don't allow links in label: */
    & a {
      display: none !important;
    }
  }

  /* focus + checked */
  /* *************** */

  &>input:focus+label {
    &:before {
      outline: 0.2rem solid vars.$color-blue-zodiac;
      outline-offset: 0.2rem;
    }
  }

  &>input:checked+label {
    &:before {
      background-color: vars.$color-blue-zodiac;
      border-color: vars.$color-blue-zodiac;
    }

    &:after {
      opacity: 1;
      visibility: visible;
      transform: scale(1);
    }
  }
}
