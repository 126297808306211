/** --------------------------------------------------
*
*   EstablishmentsPage
*
*   TABLE OF CONTENTS
*   establishment navigation
*   establishment info
*   establishment tile
*   establishment info
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/base/utils";
@use "~/src/styles/mixins/cta";


/** --------------------------------------------------
*   #establishment navigation
*   -------------------------------------------------- */

.establishmentNav {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 4rem;

  @include utils.mq(max l) {
    flex-direction: column;
  }
}

.establishmentNavSelectBlock {
  display: flex;
  flex-flow: row wrap;

  max-width: 62rem;
  width: 100%;
  margin-right: 2rem;

  @include utils.mq(max l) {
    max-width: 100%;
    margin-bottom: 2rem;
  }
}

.establishmentNavActionsBlock {

  @include utils.mq(max l) {
    margin-top: 2rem;
  }
}




/** --------------------------------------------------
*   #establishment info
*   -------------------------------------------------- */

.establishment {
  position: relative;
}

/* header */
/* ****** */

.establishmentHeader {
  margin-bottom: 3rem;
}

.establishmentTitle {
  font-size: 4rem;
  line-height: 1.2;

  @include utils.mq(max s) {
    font-size: 2.8rem;
  }
}

.establishmentRemoveButton {
  @include cta.buttonSkeleton();
  display: block;
  font-size: 1.4rem;
  line-height: 1.5;
  font-weight: 600;
  text-decoration: underline;
  color: vars.$color-blue-zodiac;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

/* data */
/* **** */

.establishmentData {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  @include utils.mq(max m) {
    flex-direction: column;
  }
}

.establishmentDataMain {
  flex: 0 1 44rem;
  width: 44rem;
  min-width: 0;

  @include utils.mq(max m) {
    flex-basis: 100%;
    width: 100%;
  }
}

.establishmentDataAdditional {
  flex: 0 1 calc(100% - 62rem);
  width: calc(100% - 62rem);

  @include utils.mq(max m) {
    flex-basis: 100%;
    width: 100%;
    margin-top: 2rem;
  }
}




/** --------------------------------------------------
*   #establishment tile
*   -------------------------------------------------- */

.tile {
  position: relative;
}

/* tile inner */
/* ********** */

.tileInner {
  display: flex;
  flex-flow: row wrap;
  padding: 3rem;
  border: 0.1rem solid vars.$color-brand;
  border-radius: 1rem;
}

.tileInnerIcon {
  flex: 0 0 auto;
  min-width: 0;
  margin-right: 2rem;

  & svg {
    width: 1.6rem;
    height: auto;
  }
}

.tileInnerContent {
  flex: 1 1 auto;
  min-width: 0;
}

.tileAddress {}

.tileAddressName {
  display: block;
  font-weight: 600;
}

.tileAddressLine {
  display: block;
  margin-top: 1rem;
}

/* tile outer */
/* ********** */

.tileOuter {
  margin-top: 2rem;
}

.tileOuterItem {}

.tileOuterItemLabel {
  margin-right: 0.4rem;
  font-weight: 600;
}




/** --------------------------------------------------
*   #establishment info
*   -------------------------------------------------- */

.establishmentInfo {
  position: relative;
}

.establishmentInfoItem {}

.establishmentInfoItemLabel {
  font-weight: 600;
}

.establishmentInfoItemValue {}
