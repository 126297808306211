/** --------------------------------------------------
*
*   TemplateTab
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/base/utils";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.uploadTitle {
  margin-bottom: 3rem;
  padding-bottom: 2rem;
  border-bottom: 0.2rem solid vars.$color-geyser;
}

.uploadDropdown {
  margin-top: 3rem;
  margin-bottom: 4rem;
  padding-bottom: 4rem;
  border-bottom: 0.2rem solid vars.$color-geyser;

  label {
    font-weight: 700;
    font-size: 2rem;
  }
}

.uploadSubmit {
  display: flex;
  justify-content: flex-end;

  button {
    width: 65%;

    @include utils.mq(max s) {
      width: 100%;
    }
  }
}
