/** --------------------------------------------------
*
*   CommuteTransportModeChart
*
*   TABLE OF CONTENTS
*
*   -------------------------------------------------- */

.figureBarItem {
  &[data-type="0-5"] {
    background-color: #C9EBF7;
  }

  &[data-type="5-10"] {
    background-color: #ABCCDD;
  }

  &[data-type="10-15"] {
    background-color: #8DADC3;
  }

  &[data-type="15-20"] {
    background-color: #6F8EAA;
  }

  &[data-type="20-30"] {
    background-color: #506F90;
  }

  &[data-type="30-50"] {
    background-color: #325076;
  }

  &[data-type="50-null"] {
    background-color: #14315C;
  }
}

.figureLegendItem {

  &[data-type="0-5"] {
    &::before {
      background-color: #C9EBF7;
      border-color: #C9EBF7;
    }
  }

  &[data-type="5-10"] {
    &::before {
      background-color: #ABCCDD;
      border-color: #ABCCDD;
    }
  }

  &[data-type="10-15"] {
    &::before {
      background-color: #8DADC3;
      border-color: #8DADC3;
    }
  }

  &[data-type="15-20"] {
    &::before {
      background-color: #6F8EAA;
      border-color: #6F8EAA;
    }
  }

  &[data-type="20-30"] {
    &::before {
      background-color: #506F90;
      border-color: #506F90;
    }
  }

  &[data-type="30-50"] {
    &::before {
      background-color: #325076;
      border-color: #325076;
    }
  }

  &[data-type="50-null"] {
    &::before {
      background-color: #14315C;
      border-color: #14315C;
    }
  }
}