/** --------------------------------------------------
*
*   Header
*
*   TABLE OF CONTENTS
*   general
*   logo
*   navigation
*   masquerade
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/base/utils";
@use "~/src/styles/mixins/cta";
@use "~/src/styles/mixins/layout";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.header {
  position: relative;
  background-color: vars.$color-brand;
  padding: vars.$site-padding 0 0 0;

  @media print {
    display: none;
  }
}

.inner {
  @include layout.contentWidth($withPadding: true, $large: true);
  padding-bottom: 1rem;

  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}




/** --------------------------------------------------
*   #logo
*   -------------------------------------------------- */

.logo {
  margin-right: vars.$site-padding;
  margin-bottom: 2rem;
  padding: 0.4rem 0;
  width: 100%;
  max-width: 17rem;

  & a {
    display: block;
  }

  & img {
    display: block;
    width: 100%;
    height: auto;
  }
}




/** --------------------------------------------------
*   #navigation
*   -------------------------------------------------- */

.nav {
  margin-bottom: 2rem;
  color: vars.$color-white;
  font-weight: 600;

  & button {
    @include cta.buttonSkeleton();
    font-weight: inherit;
  }

  & a,
  & button {
    padding: 1rem 0;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

.navlist {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.navitem {
  flex: 0 1 auto;
  min-width: 0;
  margin-right: 5rem;

  @include utils.mq(max xs) {
    width: 100%;
    margin-right: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.navitem + .navitem {
  @include utils.mq(max xs) {
    margin-top: 1rem;
  }
}


/** --------------------------------------------------
*   #masquerade
*   -------------------------------------------------- */

.masqueradeNotification {
  display: flex;
  gap: 2.4rem;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  background-color: vars.$color-white;
  padding: 2.4rem;
  text-align: center;
}