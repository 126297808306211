/** --------------------------------------------------
*
*   variables
*   MEASUREMENTS
*
*   TABLE OF CONTENTS
*   site widths
*   site padding
*
*   -------------------------------------------------- */




/** --------------------------------------------------
*   #site widths
*   -------------------------------------------------- */

$site-width: 192rem;
$content-width--large: 114rem;
$content-width: 108rem;
$inner-width: 71.2rem;




/** --------------------------------------------------
*   #site padding
*   -------------------------------------------------- */

// horizontal site padding:

$site-padding: 3.4rem;
$site-padding--mobile: 2rem;

// vertical content padding:

$content-spacing-vertical: 5.5rem;
