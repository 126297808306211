/** --------------------------------------------------
*
*   mixins - layout
*   CONTENT WIDTH
*
*   TABLE OF CONTENTS
*   content width
*
*  -------------------------------------------------- */


@use "../../variables" as vars;
@use "../../base/utils";


/** --------------------------------------------------
*  #content width
*  -------------------------------------------------- */

@mixin contentWidth($withPadding: true, $large: false) {
  $maxWidth: vars.$content-width;

  @if $large ==true {
    $maxWidth: vars.$content-width--large;
  }

  width: 100%;
  max-width: $maxWidth;
  margin-left: auto;
  margin-right: auto;

  @if $withPadding ==true {
    max-width: $maxWidth + (vars.$site-padding * 2);
    padding-left: vars.$site-padding;
    padding-right: vars.$site-padding;

    @include utils.mq(max l) {
      max-width: $maxWidth + (vars.$site-padding--mobile * 2);
      padding-left: vars.$site-padding--mobile;
      padding-right: vars.$site-padding--mobile;
    }
  }
}
