/** --------------------------------------------------
*
*   base
*   FONTS
*
*   TABLE OF CONTENTS
*   montserrat
*
*   -------------------------------------------------- */




/** --------------------------------------------------
*  #montserrat
*  -------------------------------------------------- */

/**
* Montserrat
*
* available from:
* https://github.com/JulietaUla/Montserrat
* https://fonts.google.com/specimen/Montserrat
* https://www.fontsquirrel.com/fonts/montserrat
*
* webfont downloaded from:
* https://github.com/JulietaUla/Montserrat
* and generated using:
* https://www.fontsquirrel.com/tools/webfont-generator
*
* license:
* Open Font License
* https://scripts.sil.org/cms/scripts/page.php?site_id=nrsi&id=OFL
*/

/* light (300) */
/* *********** */

@font-face {
  font-family: 'Montserrat';
  src: url('/assets/fonts/montserrat/montserrat-light-webfont.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/assets/fonts/montserrat/montserrat-lightitalic-webfont.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

/* regular (400) */
/* ************* */

@font-face {
  font-family: 'Montserrat';
  src: url('/assets/fonts/montserrat/montserrat-regular-webfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/assets/fonts/montserrat/montserrat-italic-webfont.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

/* semibold (600) */
/* ************** */

@font-face {
  font-family: 'Montserrat';
  src: url('/assets/fonts/montserrat/montserrat-semibold-webfont.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/assets/fonts/montserrat/montserrat-semibolditalic-webfont.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

/* bold (700) */
/* ********** */

@font-face {
  font-family: 'Montserrat';
  src: url('/assets/fonts/montserrat/montserrat-bold-webfont.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/assets/fonts/montserrat/montserrat-bolditalic-webfont.woff2') format('woff2');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
