/** --------------------------------------------------
*
*   components - forms
*   FORM LAYOUT
*
*   TABLE OF CONTENTS
*   general
*   form actions
*   form errors
*
*   -------------------------------------------------- */


@use "../../variables" as vars;
@use "../../base/utils";
@use "../../mixins/cta";
@use "../../mixins/text";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.form-input {
  width: 100%;
}

.form-row {
  margin-bottom: 2rem;
}

/* form labels */
/* *********** */

.form-label {
  display: inline-block;
  margin-bottom: 0.8rem;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.5;
}

/* form items */
/* ********** */

.form-item label {
  display: inline-block;
  margin-bottom: 0.8rem;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.5;
}




/** --------------------------------------------------
*   #form actions
*   -------------------------------------------------- */

.form-actions {
  display: flex;
  flex-flow: row wrap;
  margin: 2rem -0.5rem 1rem;
}

.form-action {
  flex: 0 1 auto;
  min-width: 0;
  padding: 0 0.5rem 1rem;
}

.form-button {
  @include cta.ctaPrimary();
}

.form-action--large {
  width: 100%;

  .form-button {
    width: 100%;
  }
}
