/** --------------------------------------------------
*
*   EmployeeMapIcon
*
*   TABLE OF CONTENTS
*   icon
*
*   -------------------------------------------------- */


@use "sass:map";
@use "~/src/styles/variables" as vars;


/** --------------------------------------------------
*   #icon
*   -------------------------------------------------- */

.outerIcon {
  background-color: vars.$color-white;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &[data-type=no_potential] {
    background-color: transparent;
    border-radius: 0;
    border-left: 0.9rem solid transparent;
    border-right: 0.9rem solid transparent;
    border-bottom: 1.8rem solid gray;
  }
}

.innerIcon {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &[data-type=no_potential] {
    display: none;
    // background-color: gray;
  }
}

@each $key,
$value in vars.$transporttype-colors {
  .innerIcon[data-type='#{$key}'] {
    background-color: map-get($value, main);
  }
}