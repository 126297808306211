/** --------------------------------------------------
*
*   PageIntro
*
*   TABLE OF CONTENTS
*   general
*   layout
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/base/utils";
@use "~/src/styles/mixins/layout";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.container {
  position: relative;
  z-index: 1;
  color: vars.$color-white;
  background-color: vars.$color-brand;
  padding: 9.4rem 0 8rem 0;

  @include utils.mq(max l) {
    padding: 3.4rem 0;
  }

  /* background decorations: */
  &::before,
  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: auto auto;
    background-position: right bottom;

    @include utils.mq(max l) {
      background-size: contain;
    }

    @include utils.mqset(highcontrast) {
      display: none;
    }

    @include utils.mqset(forcedcolors-more) {
      display: none;
    }
  }

  &::before {
    z-index: 1;
    background-image: url('../../../../assets/images/bg-header--darken.svg');
    opacity: 0.2;
  }

  &::after {
    z-index: 2;
    background-image: url('../../../../assets/images/bg-header--colored.svg');
    opacity: 0.9;
  }
}

.inner {
  position: relative;
}

/* brand logo floater */
/* ****************** */

.brandLogoFloater {
  width: 13.3rem;
  height: 12.2rem;
  position: absolute;
  z-index: 3;
  right: 0;
  bottom: -6rem;

  @include utils.mq(max xxxl) {
    display: none;
  }

  & img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: right bottom;
  }
}




/** --------------------------------------------------
*   #layout
*   -------------------------------------------------- */

/* top container */
/* ************* */

.top {
  @include layout.contentWidth($withPadding: true, $large: true);
  position: relative;
  z-index: 4;
  margin-bottom: 3.4rem;
}

/* left-right container */
/* ******************** */

.leftright {
  @include layout.contentWidth($withPadding: true, $large: true);
  position: relative;
  z-index: 4;

  display: flex;
  flex-flow: row wrap;
}

.left {
  min-width: 0;
  flex: 1 1 auto;
  width: 50%;

  @include utils.mq(max l) {
    width: 100%;
  }
}

.leftInner {
  margin-right: 3rem;

  @include utils.mq(max l) {
    margin-right: 0;
  }
}

.right {
  min-width: 0;
  flex: 0 1 auto;
  width: 50%;

  @include utils.mq(max l) {
    width: 100%;
  }
}

.left + .right {
  @include utils.mq(max l) {
    margin-top: 3.4rem;
  }
}

.rightInner {
  margin-left: 5.5rem;

  @include utils.mq(max l) {
    margin-left: 0;
  }
}

/* bottom container */
/* **************** */

.bottom {
  @include layout.contentWidth($withPadding: true, $large: false);
  position: relative;
  z-index: 4;
  margin-top: 8rem;
}
