/** --------------------------------------------------
*
*   ReportActions
*
*   TABLE OF CONTENTS
*   general
*   options list
*   option block
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/cta";
@use "~/src/styles/base/utils";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.container {
  overflow: hidden;

  @media print {
    display: none;
  }
}

.inner {
  width: 100%;
  max-width: 130rem;
  margin-right: auto;
  margin-left: auto;
}




/** --------------------------------------------------
*   #options list
*   -------------------------------------------------- */

.list {
  display: flex;
  flex-flow: row wrap;
}

.listItem {
  position: relative;
  flex: 0 1 auto;
  min-width: 0;
  width: 50%;
  background-color: vars.$color-background-light;

  @include utils.mq(max m) {
    width: 100%;
  }

  // extend the background:
  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    width: 50vw;
    background-color: vars.$color-background-light;

    @include utils.mq(max m) {
      display: none;
    }
  }

  // change the colour of the first item:
  &:first-child {
    background-color: vars.$color-kournikova;

    &::after {
      right: 0;
      left: auto;
      background-color: vars.$color-kournikova;
    }
  }
}




/** --------------------------------------------------
*   #option block
*   -------------------------------------------------- */

.option {
  position: relative;
  z-index: 2;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 8.4rem 7.4rem;

  @include utils.mq(max xl) {
    padding: 7rem 4rem;
  }

  @include utils.mq(max s) {
    padding: 3.2rem 2rem;
  }
}

/* content block */
/* ************* */

.optionContent {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  padding-right: 10%;
}

.optionContentImage {
  flex: 0 0 auto;
  min-width: 0;
  width: 4.6rem;
  margin-right: 2rem;

  & img {
    display: block;
    width: 100%;
  }
}

.optionContentMain {
  flex: 1 1 auto;
  min-width: 0;
}

.optionTitle {
  margin-bottom: 0.5rem;
  font-size: 2rem;
  line-height: 1.2;
  font-weight: bold;

  @include utils.mq(max s) {
    font-size: 1.6rem;
  }

  & button {
    @include cta.buttonSkeleton();
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;

    &::after {
      content: '';
      display: block;
      position: absolute;
      z-index: 3;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
}

.optionText {
  font-size: 1.5rem;
  line-height: 1.8;
  font-weight: 500;
}

/* arrow holder */
/* ************ */

.optionArrowHolder {
  flex: 0 0 auto;
  min-width: 0;
  width: 3.2rem;
  height: 3.2rem;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  color: vars.$color-brand;
  border: 0.2rem solid;
  border-radius: 50%;
  background-color: vars.$color-background-light;
  transition: background-color 0.15s ease-out;

  & svg {
    width: 1.4rem;
    height: 1.4rem;
  }
}

.option:hover .optionArrowHolder,
.option:focus-within .optionArrowHolder {
  background-color: vars.$color-white;
}

/* loading wrapper */
/* *************** */

.optionLoadingWrapper {
  position: relative;
  z-index: 2;
}
