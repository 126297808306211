/** --------------------------------------------------
*
*   LoadingIndicator
*
*   TABLE OF CONTENTS
*   general
*   dots
*   text
*
*   -------------------------------------------------- */


@use "../../../styles/variables" as vars;
@use "../../../styles/base/utils";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.loadingIndicator {
  margin: 5.5rem auto;
}

.loadingIndicator[data-displaytype="minimal"] {
  margin: 1em auto;
}




/** --------------------------------------------------
*   #dots
*   -------------------------------------------------- */

.dot {
  position: relative;
  width: 4rem;
  height: 4rem;
  margin: 0 auto 1.5rem auto;
}

.loadingIndicator[data-displaytype="minimal"] .dot {
  width: 1em;
  height: 1em;
}

.dotItem {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  &::before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: vars.$color-blue-zodiac;
    border-radius: 100%;
    animation: dotsBounce 1.2s infinite ease-in-out both;

    @include utils.mqset(motion) {
      animation: none;
    }
  }
}

.dotItem2 {
  transform: rotate(30deg);

  &::before {
    animation-delay: -1.1s;
  }
}

.dotItem3 {
  transform: rotate(60deg);

  &::before {
    animation-delay: -1s;
  }
}

.dotItem4 {
  transform: rotate(90deg);

  &::before {
    animation-delay: -0.9s;
  }
}

.dotItem5 {
  transform: rotate(120deg);

  &::before {
    animation-delay: -0.8s;
  }
}

.dotItem6 {
  transform: rotate(150deg);

  &::before {
    animation-delay: -0.7s;
  }
}

.dotItem7 {
  transform: rotate(180deg);

  &::before {
    animation-delay: -0.6s;
  }
}

.doItemt8 {
  transform: rotate(210deg);

  &::before {
    animation-delay: -0.5s;
  }
}

.dotItem9 {
  transform: rotate(240deg);

  &::before {
    animation-delay: -0.4s;
  }
}

.dotItem10 {
  transform: rotate(270deg);

  &::before {
    animation-delay: -0.3s;
  }
}

.dotItem11 {
  transform: rotate(300deg);

  &::before {
    animation-delay: -0.2s;
  }
}

.dotItem12 {
  transform: rotate(330deg);

  &::before {
    animation-delay: -0.1s;
  }
}

@keyframes dotsBounce {

  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}




/** --------------------------------------------------
*   #text
*   -------------------------------------------------- */

.text {
  font-weight: 600;
  text-align: center;
}

.loadingIndicator[data-displaytype="minimal"] .text {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
