/** --------------------------------------------------
*
*   PasswordPage
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "~/src/styles/mixins/text";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.title {
  @include text.contentTitle();
  margin-bottom: 2rem;
}
