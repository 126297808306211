/** --------------------------------------------------
*
*   TabLabel
*
*   TABLE OF CONTENTS
*   structure
*   tab image
*   tab label
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;


/** --------------------------------------------------
*   #structure
*   -------------------------------------------------- */

.tab {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.tabImage {
  flex: 0 0 3.9rem;
  min-width: 0;
  padding-right: 2rem;

  & svg {
    width: 2.4rem;
    height: 2.4rem;
  }
}

.tabLabel {
  flex: 1 1 calc(100% - 3.9rem);
  min-width: 0;
}
