/** --------------------------------------------------
*
*   base
*   LINK
*
*   TABLE OF CONTENTS
*   link
*
*   -------------------------------------------------- */


@use "../variables" as vars;
@use "../mixins/link" as link;


/** --------------------------------------------------
*   #link
*   -------------------------------------------------- */

.link-text {
  @include link.linkBasic();
  font-weight: 600;
  text-decoration: underline;
  color: vars.$color-brand;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  span {
    margin-right: 0.75rem;
  }

  & svg {
    height: 1em;
  }
}
