/** --------------------------------------------------
*
*   Province HomePage
*
*   TABLE OF CONTENTS
*   intro
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/cta";
@use "~/src/styles/mixins/text";
@use "~/src/styles/base/utils";


/** --------------------------------------------------
*   #intro
*   -------------------------------------------------- */

.introBlock {
  padding: 2rem 0 4rem;

  @include utils.mq(max l) {
    padding: 0;
  }
}

.introTitle {
  @include text.pageTitle();
  margin-bottom: 2rem;
}

.introDescription {
  font-size: 1.6rem;
  line-height: 1.75;
}
