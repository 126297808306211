/** --------------------------------------------------
*
*   ConfirmModal
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/base/utils";
@use "~/src/styles/mixins/cta";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

/* overlay */
/* ******* */

.modalOverlay {
  position: fixed;
  z-index: utils.z(modal);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(241, 245, 250, 0.65);

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  opacity: 0;
  transition: opacity 0.15s ease-in-out;
}

.modalOverlayAfterOpen {
  opacity: 1;
}

.modalOverlayBeforeClose {
  opacity: 0;
}

/* modal */
/* ***** */

.modal {
  width: calc(90% - 2.2rem);
  max-width: 50rem;
}

.modalContent {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;

  text-align: center;
}




/** --------------------------------------------------
*   #modal content: header
*   -------------------------------------------------- */

.modalHeader {
  position: relative;
  flex: 0 1 auto;
  width: 100%;
}

/* close button */
/* ************ */

.modalCloseButton {
  @include cta.buttonSkeleton();

  position: absolute;
  top: -2.2rem;
  right: -2.2rem;

  width: 4.4rem;
  height: 4.4rem;
  border: 0.2rem solid vars.$color-geyser;
  border-radius: 100%;
  background-color: vars.$color-blue-zodiac;
  color: vars.$color-white;

  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    width: 100%;
    height: 100%;
  }

  &:hover,
  &:focus {
    & svg {
      transform: scale(0.95);
    }
  }

  &:active {
    transform: scale(0.95);
  }
}




/** --------------------------------------------------
*   #modal content: main
*   -------------------------------------------------- */

.modalMain {
  flex: 1 1 auto;
  width: 100%;
  overflow-x: auto;

  display: flex;
  flex-flow: column nowrap;

  background-color: vars.$color-white;
  padding: 3.2rem;
  border: 0.2rem solid vars.$color-geyser;

  @include utils.mq(max s) {
    padding: 2rem;
  }
}

/* title */
/* ***** */

.modalTitle {
  font-size: 2.4rem;
  line-height: 1.4;

  @include utils.mq(max s) {
    font-size: 2rem;
  }
}

/* actions */
/* ******* */

.modalActions {
  margin: 3.2rem 0 0;
}

.modalActionApprove {
  @include cta.ctaPrimary();
  margin: 1rem;
}

.modalActionReject {
  @include cta.ctaPrimaryInverse();
  margin: 1rem;
}
