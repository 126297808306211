/** --------------------------------------------------
*
*   CommuteDistanceChart
*
*   TABLE OF CONTENTS
*   figure bar
*   legend
*
*   -------------------------------------------------- */


@use "sass:map";
@use "~/src/styles/variables" as vars;


/** --------------------------------------------------
*   #figure bar
*   -------------------------------------------------- */

.figureBarItem {
  background-color: transparent;
}

@each $key,
$value in vars.$transporttype-colors {
  .figureBarItem[data-type='#{$key}'] {
    background-color: map-get($value, main);
  }
}




/** --------------------------------------------------
*   #legend
*   -------------------------------------------------- */

@each $key,
$value in vars.$transporttype-colors {
  .figureLegendItem[data-type='#{$key}']::before {
    background-color: map-get($value, complement);
    border-color: map-get($value, main);
  }
}
