/** --------------------------------------------------
*
*   CommutingTab
*
*   TABLE OF CONTENTS
*   general
*   header
*   content
*   general chart
*   distance chart
*   distance chart: graphic
*   distance chart: legend
*   means of transport chart
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/cta";
@use "~/src/styles/mixins/layout";
@use "~/src/styles/mixins/text";
@use "~/src/styles/base/utils";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.container {}


/** --------------------------------------------------
*   #header
*   -------------------------------------------------- */

.header {
  margin-bottom: 2rem;
}

.headerInner {
  @include layout.contentWidth();
}

.headerTitle {}


/** --------------------------------------------------
*   #content
*   -------------------------------------------------- */

.content {}

.contentInner {
  @include layout.contentWidth();
}



/** --------------------------------------------------
*   #general chart
*   -------------------------------------------------- */

.generalChart {
  // @include layout.contentWidth();
  // padding-top: 8rem;
  // padding-bottom: 8rem;
}



/** --------------------------------------------------
*   #means of transport chart
*   -------------------------------------------------- */

.meansOfTransportChart {
  // @include layout.contentWidth();
  // padding-top: 8rem;
  // padding-bottom: 8rem;
}


.generalChartContent {
  margin-top: 4rem;
}