/** --------------------------------------------------
*
*   EditDraftPage
*
*   TABLE OF CONTENTS
*   info block
*   content layout
*   info main block
*   info meta block
*   content: section list
*   info mobiscan block
*   create form
*   information message
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/layout";
@use "~/src/styles/base/utils";
@use "~/src/styles/mixins/cta";


/** --------------------------------------------------
*   #info block
*   -------------------------------------------------- */

.info {
  margin-top: vars.$content-spacing-vertical;
  padding-bottom: vars.$content-spacing-vertical;
  border-bottom: 0.2rem solid vars.$color-geyser;
}

.infoInner {
  @include layout.contentWidth();
  display: flex;
  flex-flow: row wrap;
}

.infoMainBlock {
  flex: 0 1 auto;
  min-width: 0;
  width: 65%;
  padding-right: 8%;

  @include utils.mq(max m) {
    width: 100%;
    padding-right: 0;
    margin-bottom: 3.2rem;
  }
}

.infoMetaBlock {
  flex: 0 1 auto;
  min-width: 0;
  width: 35%;

  @include utils.mq(max m) {
    width: 100%;
  }
}




/** --------------------------------------------------
*   #content layout
*   -------------------------------------------------- */

.contentLayout {
  @include layout.contentWidth();
  margin-top: vars.$content-spacing-vertical;
}

.contentLayoutInner {
  display: flex;
  flex-flow: row wrap;
}

.contentLayoutMain {
  flex: 1 1 auto;
  min-width: 0;
  width: 65%;
  padding-right: 8%;
  margin-bottom: 2rem;

  @include utils.mq(max m) {
    width: 100%;
    padding-right: 0;
    margin-bottom: 3.2rem;
  }
}

.contentLayoutSide {
  flex: 1 1 auto;
  min-width: 0;
  width: 35%;
  margin-bottom: vars.$content-spacing-vertical;

  @include utils.mq(max m) {
    width: 100%;
    margin-bottom: 1.2rem;
  }
}




/** --------------------------------------------------
*   #info main block
*   -------------------------------------------------- */

.subtitle {
  font-size: 2.4rem;
  margin-bottom: 1.5rem;
}

.address {
  line-height: 1.75;
}




/** --------------------------------------------------
*   #info meta block
*   -------------------------------------------------- */

.infoButtonHolder {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1.5rem;

  @include utils.mq(max m) {
    justify-content: flex-start;
  }
}

.infoButton {
  @include cta.ctaPrimaryInverse();
  padding: 0.3rem 0.8rem;

  & svg {
    fill: vars.$color-brand;
  }
}

.metaBlock {
  padding: 2rem;
  border-radius: 1rem;
  background-color: vars.$color-black-squeeze;
}

.itemMetaBlock {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 1rem;

  & > * {
    flex: 1 1 50%;
    min-width: 0;

    @include utils.mq(max s) {
      flex-basis: 100%;
    }
  }
}

.labelMetaBlock {
  font-weight: 600;
}

.establishmentRemoveButton {
  @include cta.buttonSkeleton();
  display: block;
  font-size: 1.2rem;
  line-height: 1.5;
  font-weight: 600;
  text-decoration: underline;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}




/** --------------------------------------------------
*   #content: section list
*   -------------------------------------------------- */

.sectionList {
  list-style: none;
}

.sectionListItem + .sectionListItem {
  margin-top: 2rem;
}

/* section block */
/* ************* */

.sectionBlock {
  border: 0.1rem solid vars.$color-brand;
  border-radius: 1rem;
  background-color: vars.$color-catskill-white;
  overflow: hidden;

  &[data-status="done"] {
    background-color: vars.$color-background-light;
  }
}

.sectionBlockMain {
  padding: 2.8rem 2.4rem 2.2rem 8rem;

  @include utils.mq(max l) {
    padding: 2rem 2.4rem;
  }
}

.sectionBlockHeader {
  margin-bottom: 0.6rem;
}

.sectionBlockTitle {
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 1.3;

  @include utils.mq(max s) {
    font-size: 2rem;
  }
}

.sectionBlockContent {
  font-size: 1.6rem;
  line-height: 1.75;
}

.sectionBlockFooter {
  padding: 1.2rem 2.4rem;
  background-color: vars.$color-white;
}

.sectionBlockFooterInner {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 0 -1rem -1rem;
}

.sectionBlockStatus {
  padding: 0 1rem 1rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.sectionBlockStatusIcon {
  flex: 0 0 auto;
  min-width: 0;
  width: 5.6rem;
  position: relative;

  @include utils.mq(max l) {
    width: 4.8rem;
  }

  &::before {
    content: '';
    display: block;
    width: 3.2rem;
    height: 3.2rem;
    border: 0.2rem solid vars.$color-blue-zodiac;
    border-radius: 50%;
    background-color: vars.$color-kournikova;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0.9rem;
    left: 0.8rem;
    width: 1.7rem;
    height: 1rem;
    transform: rotate(-45deg);
    border: solid vars.$color-blue-zodiac;
    border-width: 0 0 0.3rem 0.3rem;
    border-top-color: transparent;
    background: transparent;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.2s ease, visibility 0.2s ease;
  }
}

.sectionBlock[data-status="open"] .sectionBlockStatusIcon {

  &::before {
    border-color: vars.$color-error-foreground;
    background-color: vars.$color-white;
  }

  &::after {
    opacity: 0;
    visibility: hidden;
  }
}

.sectionBlockStatusText {
  flex: 1 1 auto;
  min-width: 0;
  font-size: 1.2rem;
  line-height: 1.3;
  font-weight: 600;
}

.sectionBlock[data-status="open"] .sectionBlockStatusText {
  color: vars.$color-error-foreground;
}

.sectionBlockActions {
  padding: 0 1rem 1rem;

  & a {
    @include cta.ctaPrimary();

    padding: 0.6rem 2rem;
    border-radius: 0.5rem;
    font-size: 1.2rem;
  }
}




/** --------------------------------------------------
*   #info mobiscan block
*   -------------------------------------------------- */

.infoMobiscanBlock {}

.infoMobiscanBlockTitle {
  margin-bottom: 2rem;
  font-size: 1.6rem;
  line-height: 1.5;
}

.infoMobiscanBlockList {
  margin-bottom: 1rem;

  & li {
    display: flex;
    align-items: baseline;

    & svg {
      flex: 0 0 auto;
      min-width: 0;
      margin-right: 1.2rem;
      fill: vars.$color-curious-blue;
    }
  }

  & li + li {
    margin-top: 1.2rem;
  }
}




/** --------------------------------------------------
*   #create form
*   -------------------------------------------------- */

.createform {
  @include layout.contentWidth();
  margin-top: 2rem;
  margin-bottom: vars.$content-spacing-vertical;
}

.createformInner {
  max-width: 57%;

  @include utils.mq(max m) {
    max-width: 100%;
  }
}

.sectorForm {
  margin-bottom: 4rem;
}

.legalForm {
  margin-bottom: 2rem;
}

.legal {}

.legalItems {
  margin: 0.6rem -1rem -2rem 3.2rem;
  display: flex;
  flex-flow: row wrap;
}

.legalItem {
  padding: 0 1rem 2rem;
}





/** --------------------------------------------------
*   #information message
*   -------------------------------------------------- */

.informationMessage {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  margin-top: 2rem;

  & svg {
    flex: 0 0 3.5rem;
    width: 2rem;
    min-width: 0;
    height: auto;
    padding-right: 1.5rem;
  }

  & div {
    flex: 0 1 calc(100% - 4rem);
    min-width: 0;

    & span {
      width: 36rem;
      max-width: 100%;
      font-weight: 600;
    }
  }
}