/** --------------------------------------------------
*
*   FullPageLoadingIndicator
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "../../../styles/variables" as vars;
@use "../../../styles/base/utils";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: vars.$color-catskill-white;
  font-weight: 600;
  text-align: center;
}

.bike {
  height: 9rem;
  margin-bottom: 2rem;
  animation: illusion 0.3s linear 1;

  & img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    animation: wobble 0.4s linear infinite;
  }
}

// .car {
//   animation: illusion 0.3s linear 1;

//   & svg {
//     width: 16.4rem;
//     height: 16.2rem;
//     animation: wobble 0.4s linear infinite;
//   }

//   & .tires {
//     animation: tires 0.35s linear infinite;
//   }
// }

@keyframes illusion {
  0% {
    transform: scale(0.6);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes wobble {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(0, -0.4rem);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes tires {
  0% {
    transform: translate(0, 0);
  }

  33% {
    transform: translate(-0.02rem, -0.05rem);
  }

  66% {
    transform: translate(0.02rem, 0.05rem);
  }

  100% {
    transform: translate(0, 0);
  }
}