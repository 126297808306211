/** --------------------------------------------------
*
*   CreateMobiscanPage
*
*   TABLE OF CONTENTS
*   page layout
*   info panel
*   actions panel
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/cta";
@use "~/src/styles/mixins/layout";
@use "~/src/styles/mixins/text";
@use "~/src/styles/base/utils";


/** --------------------------------------------------
*   #page layout
*   -------------------------------------------------- */

.layout {
  @include layout.contentWidth();
  margin-top: vars.$content-spacing-vertical;
  margin-bottom: vars.$content-spacing-vertical;
}

.layoutColumns {
  display: flex;
  flex-flow: row wrap;
}

/* info column */
/* *********** */

.layoutInfoColumn {
  flex: 0 1 auto;
  min-width: 0;
  width: 42%;
  padding-right: 10%;

  @include utils.mq(max m) {
    width: 100%;
    padding-right: 0;
    margin-bottom: 3.2rem;
  }
}

/* action column */
/* ************* */

.layoutActionColumn {
  flex: 0 1 auto;
  min-width: 0;
  width: 58%;

  @include utils.mq(max m) {
    width: 100%;
  }
}




/** --------------------------------------------------
*   #info panel
*   -------------------------------------------------- */

.infopanel {}


/* content */
/* ******* */

.infopanelContent {}

.infoLink {
  @include cta.buttonSkeleton();
  text-decoration: underline;

  &:focus,
  &:hover {
    color: vars.$color-matisse;
  }
}




/** --------------------------------------------------
*   #actions panel
*   -------------------------------------------------- */

.actionspanel {}

/* header */
/* ****** */

.actionspanelHeader {}

.actionspanelTitle {
  @include text.contentTitle();
  margin-bottom: 2rem;
}

.actionspanelHeaderActions {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  margin-bottom: 2rem;
}

.actionspanelHeaderAction {
  font-size: 1.4rem;
  line-height: 1.2;
  font-weight: 600;
  text-decoration: underline;
  color: vars.$color-text--light;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

/* content */
/* ******* */

.actionspanelForm {}

.actionspanelFormActions {
  margin-top: 3rem;
}