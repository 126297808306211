/** --------------------------------------------------
*
*   ConfirmEstablishment
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/text";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.content {
  margin-bottom: vars.$site-padding;
}

.title {
  @include text.contentTitle();
  margin-bottom: 2rem;
}

.establishment {
  margin-top: vars.$site-padding;
}

.options {
  margin-top: vars.$site-padding;
  padding-bottom: 1rem;
  border-bottom: 0.2rem solid vars.$color-geyser;
}

.organizations {
  margin-bottom: 2rem;
  padding-left: 4rem;
}

.cluster {
  margin-bottom: vars.$site-padding;
}
