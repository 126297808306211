/** --------------------------------------------------
*
*   base - mixins - cta
*   CTA BASIC
*
*   TABLE OF CONTENTS
*   cta primary
*   cta primary inverse
*   cta secondary
*
*  -------------------------------------------------- */


@use "button-skeleton";
@use "../../variables" as vars;


/** --------------------------------------------------
*  #cta primary
*  -------------------------------------------------- */

@mixin ctaPrimary() {
  @include button-skeleton.buttonSkeleton();

  display: inline-flex;
  justify-content: center;
  align-items: center;

  border: 0.1rem solid vars.$color-cta-border;
  border-radius: 1rem;
  padding: 1.5rem 3.6rem;
  background-color: vars.$color-cta-background;
  color: vars.$color-cta-foreground;

  font-family: vars.$font-basic;
  font-size: 1.4rem;
  line-height: 1.572;
  font-weight: 600;
  text-align: center;

  transition: background-color 0.15s ease-in, color 0.15s ease-in, border-color 0.15s ease-in;

  &:hover,
  &:focus {
    color: vars.$color-cta-foreground--focus;
    background-color: vars.$color-cta-background--focus;
    border-color: vars.$color-cta-border--focus;
  }

  &:focus-visible,
  &:active {
    text-decoration: underline;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;

    &:hover {
      background-color: vars.$color-cta-background;
      color: vars.$color-cta-foreground;
      border-color: vars.$color-cta-border;
    }
  }

  & > svg {
    flex: 0 0 auto;
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 1rem;
  }
}




/** --------------------------------------------------
*  #cta primary inverse
*  -------------------------------------------------- */

@mixin ctaPrimaryInverse() {
  @include ctaPrimary();

  border-color: vars.$color-cta-border--inverse;
  background-color: vars.$color-cta-background--inverse;
  color: vars.$color-cta-foreground--inverse;

  &:hover,
  &:focus {
    color: vars.$color-cta-foreground--focus--inverse;
    background-color: vars.$color-cta-background--focus--inverse;
    border-color: vars.$color-cta-border--focus--inverse;
  }

  &:disabled:hover {
    background-color: vars.$color-cta-background--inverse;
    color: vars.$color-cta-foreground--inverse;
    border-color: vars.$color-cta-border--inverse;
  }

  & > svg {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
  }
}




/** --------------------------------------------------
*  #cta secondary
*  -------------------------------------------------- */

@mixin ctaSecondary() {
  @include ctaPrimary();

  border-color: vars.$color-cta-border--secondary;
  background-color: vars.$color-cta-background--secondary;
  color: vars.$color-cta-foreground--secondary;

  &:hover,
  &:focus {
    color: vars.$color-cta-foreground--secondary--focus;
    background-color: vars.$color-cta-background--secondary--focus;
    border-color: vars.$color-cta-border--secondary--focus;
  }

  &:disabled:hover {
    background-color: vars.$color-cta-background--secondary;
    color: vars.$color-cta-foreground--secondary;
    border-color: vars.$color-cta-border--secondary;
  }
}
