/** --------------------------------------------------
*
*   DropdownSmall
*
*   TABLE OF CONTENTS
*   general
*   select wrapper
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/base/utils";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.formItem {}

.formItem label {
  display: inline-block;
  margin-bottom: 0.8rem;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.5;
}




/** --------------------------------------------------
*   #select wrapper
*   -------------------------------------------------- */

.selectWrapper {
  position: relative;
  width: 100%;
  background-color: vars.$color-input-background;
  border: 0.1rem solid vars.$color-input-border;
  border-radius: 1rem;
}

.selectWrapper select {
  appearance: none;
  width: 100%;
  background: transparent;
  border: 0;
  padding: 0.6rem 4.6rem 0.6rem 2.6rem;
}

.selectWrapper:focus-within {
  border-color: vars.$color-input-border;
  box-shadow: 0 0 0 0.1rem black;
}

.selectWrapper:focus-within select {
  box-shadow: none;
}

/* hide the select arrow in IE: */
.selectWrapper select::-ms-expand {
  display: none;
}

.selectArrow {
  position: absolute;
  top: 50%;
  margin-top: -0.8rem;
  right: 2rem;
  pointer-events: none;

  & svg {
    display: block;
    width: 1.6rem;
    height: 1.6rem;
  }
}