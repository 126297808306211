/** --------------------------------------------------
*
*   MeasuresTab
*
*   TABLE OF CONTENTS
*   chart block
*   sliders
*   slider item
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/cta";
@use "~/src/styles/mixins/layout";
@use "~/src/styles/mixins/text";
@use "~/src/styles/base/utils";


/** --------------------------------------------------
*   #chart block
*   -------------------------------------------------- */

.chartBlock {
  @include layout.contentWidth();
  padding-top: vars.$content-spacing-vertical;
  padding-bottom: vars.$content-spacing-vertical;
}

.chartBlockHeader {
  margin-bottom: 4rem;
}

.chartBlockTitle {
  @include text.pageTitle();
  margin-bottom: 4rem;
}




/** --------------------------------------------------
*   #sliders
*   -------------------------------------------------- */

.slidersBlock {
  background-color: vars.$color-black-squeeze;
  margin-bottom: vars.$content-spacing-vertical;
  padding-top: vars.$content-spacing-vertical;
  padding-bottom: vars.$content-spacing-vertical;
  overflow: hidden;
}

/* slider block */
/* ************ */

.sliderBlock {
  @include layout.contentWidth();

  & .pageTitle {
    @include text.pageTitle();
    margin-top: vars.$content-spacing-vertical;
    margin-bottom: 4rem;
  }
}

.sliderBlock + .sliderBlock {
  margin-top: 7rem;
}

.sliderBlockHeader {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4.5rem;
}

.sliderBlockHeaderInner {
  @include layout.contentWidth();
}

.sliderBlockSectionTitleHolder {
  margin-bottom: 4rem;
}

.sliderBlockSectionTitle {
  @include text.pageTitle();
}

.sliderBlockTitle {
  @include text.contentTitle();
}

.sliderNav {
  display: flex;
  flex-flow: row nowrap;
}

.sliderNavButton {
  @include cta.buttonSkeleton();

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;

  &[disabled],
  &[aria-disabled="true"] {
    opacity: 0.5;
    pointer-events: none;
  }

  & svg {
    width: 1.1rem;
    height: 1.9rem;
  }
}

.sliderBlockContent {
  width: 100%;
  max-width: calc(100vw - (vars.$site-padding * 2));
  margin-bottom: 3rem;

  @include utils.mq(max l) {
    max-width: calc(100vw - (vars.$site-padding--mobile * 2));
  }
}

// :global needed for overriding
:global .swiper {
  overflow: visible;
}

:global .swiper-slide {
  opacity: 0.5;
  pointer-events: none;
  transition: opacity .3s linear;

  &-visible {
    opacity: 1;
    pointer-events: initial;
  }
}




/** --------------------------------------------------
*   #slider item
*   -------------------------------------------------- */

.measureTile {
  background-color: vars.$color-white;
  border-radius: 1rem;
  position: relative;
  overflow: hidden;
}

.measureTile + .measureTile {
  margin-top: 2.4rem;
}

.measureTileOverlayLink {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  background: transparent;
  border: 0;
  z-index: 2;
  opacity: 0;
  cursor: pointer;
}

/* image block */
/* *********** */

.measureTileImageBlock {
  position: relative;
}

.measureTileImageBlockInner {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  justify-content: flex-end;
  background-color: vars.$color-background-light;

  /* keep aspect ratio: */
  &::before {
    content: "";
    float: left;
    padding-bottom: 46%;
  }

  &::after {
    clear: left;
    content: " ";
    display: table;
  }
}

.measureTileImage {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.measureTileTags {
  display: flex;
  flex-flow: wrap-reverse;
  position: absolute;
  top: auto;
  left: 2.4rem;
  right: 2.4rem;
  bottom: 2.4rem;
}

.measureTileTag {
  margin: 0.5rem 0.5rem 0 0;
  padding: 0.2rem 1rem;
  font-size: 1.2rem;
  line-height: 1.8rem;
  border-radius: 0.5rem;

  &[data-type="yellow"] {
    background-color: vars.$color-kournikova;
    color: vars.$color-black;
  }

  &[data-type="green"] {
    background-color: vars.$color-spring-rain;
    color: vars.$color-black;
  }

  &[data-type="blue"] {
    background-color: vars.$color-matisse;
    color: vars.$color-white;
  }
}

/* content block */
/* ************* */

.measureTileContentBlock {
  padding: 2.4rem;
}

.measureTileTitle {
  height: 7.2rem;
  margin: 0 0 3.2rem;

  display: flex;
  // align-items: center;
  align-items: flex-start;
  justify-content: space-between;

  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: bold;

  @include utils.mq(max s) {
    font-size: 1.4rem;
  }
}

.measureTileTitleButton {
  @include cta.buttonSkeleton();
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  color: inherit;
  background: transparent;
  border: 0;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.measureTileTitleText {
  flex: 1 1 auto;
  min-width: 0;
  margin-right: 2rem;
}

.measureTileTitleIcon {
  flex: 0 0 auto;
  min-width: 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 3.2rem;
  height: 3.2rem;
  color: vars.$color-text;
  background-color: vars.$color-catskill-white;
  border-radius: 50%;

  & svg {
    width: 1.3rem;
    height: 1.3rem;
    position: relative;
    left: 0.1rem;
  }
}

.measureTileRatings {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 2rem;
  column-gap: 2rem;
}

.measureTileRatingTitle {
  font-size: 1.6rem;
  line-height: 1.5;

  @include utils.mq(max s) {
    font-size: 1.2rem;
  }
}

.measureTileRating {
  & ul {
    display: flex;
    gap: 0.6rem;
    margin: 1rem 0 0;
  }

  & li {
    background-color: vars.$color-matisse;
    width: 0;
    height: 0;
    border: 0.5rem solid vars.$color-matisse;
    border-radius: 50%;
    opacity: 0.2;

    &[data-active="true"] {
      opacity: 1;
    }
  }
}