/** --------------------------------------------------
*
*   MapComponent
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.MapComponent {
  width: 100%;
  padding-bottom: 88%;
  border-radius: 1rem;
  border: 0.1rem solid vars.$color-blue-zodiac;
  position: relative;
}
