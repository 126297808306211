/** --------------------------------------------------
*
*   LoginPage
*
*   TABLE OF CONTENTS
*   general
*   page intro
*   login/register
*   login
*   register
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/text";
@use "~/src/styles/base/utils";
@use "~/src/styles/mixins/cta";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.blockTitle {
  @include text.contentTitle();
  margin-bottom: 3rem;
}




/** --------------------------------------------------
*   #page intro
*   -------------------------------------------------- */

/* page title */
/* ********** */

.introTitle {
  font-size: 7.2rem;

  @include utils.mq(max l) {
    font-size: 4rem;
  }
}

/* left block */
/* ********** */

.intro {
  position: relative;
}

.introText {
  line-height: 1.75;
}

.introMore {
  margin-top: 3.4rem;
}

.introMoreTitle {
  margin-bottom: 0.5rem;
  font-size: 2rem;
  font-weight: 600;
}

.introMoreText a {
  color: vars.$color-kournikova;
  text-decoration: underline;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

/* right block */
/* *********** */

.introListHolder {
  margin-bottom: 5rem;
}

.introList {
  list-style: none;
}

.introList li {
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
  font-weight: 600;

  & svg {
    min-width: 0;
    flex: 0 0 auto;
    width: 1.4rem;
    height: 1.4rem;
    margin-right: 1.2rem;
    fill: vars.$color-curious-blue;
  }
}

.introList li + li {
  margin-top: 1.2rem;
}

.introOptions {
  margin-top: 3.4rem;
}

.introOptionsTitle {
  margin-bottom: 0.5rem;
  font-size: 2rem;
  font-weight: 600;
}

.introOptionsList li {
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
  font-weight: 400;

  & a {
    color: vars.$color-kournikova;
    text-decoration: underline;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  & svg {
    min-width: 0;
    flex: 0 0 auto;
    width: 1.4rem;
    height: 1.4rem;
    margin-right: 1.2rem;
    fill: vars.$color-curious-blue;
  }

}

.introOptionsList li + li {
  margin-top: 1.2rem;
}




/** --------------------------------------------------
*   #login/register
*   -------------------------------------------------- */

.loginRegisterContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  @include utils.mq(max l) {
    display: block;
  }
}

/* login block */
/* *********** */

.loginBlock {
  min-width: 0;
  flex: 0 1 50%;
  width: 50%;

  @include utils.mq(max l) {
    flex-basis: 100%;
    width: 100%;
  }
}

.loginBlockInner {
  margin-right: 5.5rem;

  @include utils.mq(max l) {
    margin-right: 0;
  }
}

/* register block */
/* ************** */

.registerBlock {
  min-width: 0;
  flex: 0 1 50%;
  width: 50%;

  @include utils.mq(max l) {
    flex-basis: 100%;
    width: 100%;
    margin-top: 5rem;
  }
}

.registerBlockInner {
  margin-left: 5.5rem;

  @include utils.mq(max l) {
    margin-left: 0;
  }
}




/** --------------------------------------------------
*   #login
*   -------------------------------------------------- */

.loginForm {}

.loginFormRow {}

.loginFormRow + .loginFormRow {
  margin-top: 2rem;
}

.input {
  width: 100%;

  @include utils.mq(max l) {
    margin-width: 40rem;
  }
}

.loginFormActions {
  margin-top: 3rem;
}

.passwordResetHolder {
  margin-top: 3rem;
}

.password_show_hide {
  @include cta.buttonSkeleton();

  position: absolute;
  z-index: 5;

  svg {
    width: 2.4rem;
    height: auto;
  }
}



/** --------------------------------------------------
*   #register
*   -------------------------------------------------- */

.registerTitle {
  @include text.contentTitle();
  margin-bottom: 2.5rem;
}

.registerText {
  margin-bottom: 5rem;
}

.registerList {
  margin-bottom: 5rem;
  list-style: none;
}

.registerList li {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  font-weight: 600;

  & svg {
    min-width: 0;
    flex: 0 0 auto;
    width: 2rem;
    height: 2rem;
    margin-right: 1.2rem;
    fill: vars.$color-curious-blue;
  }
}

.registerList li + li {
  margin-top: 1.2rem;
}

.registerCoverageText {
  margin-bottom: 3rem;
}