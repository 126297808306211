/** --------------------------------------------------
*
*   components - maps
*   LEAFLET
*
*   TABLE OF CONTENTS
*   container
*   popups
*
*   -------------------------------------------------- */


@use "../../variables" as vars;
@use "../../base/utils";


/** --------------------------------------------------
*   #container
*   -------------------------------------------------- */

.leaflet-container {
  width: 100% !important;
  height: 100% !important;

  border-radius: 1rem;

  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}




/** --------------------------------------------------
*   #popups
*   -------------------------------------------------- */

.leaflet-popup-content-wrapper {
  border: 0.1rem solid vars.$color-curious-blue;
  border-radius: 1rem;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.4);
  color: vars.$color-text;
}

.leaflet-popup-content {
  margin: 1rem 1.6rem;
  font-size: 1.2rem;
  line-height: 1.5;
  font-family: vars.$font-basic;
}

/* arrow */
/* ***** */

.leaflet-popup-tip {
  width: 13px;
  height: 13px;
}
