/** --------------------------------------------------
*
*   ValidationErrorBox
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.errorBox {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 2rem;
  color: vars.$color-error-foreground;
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: 600;
  border-left: 0.5rem solid;
  border-radius: 0.5rem;
  background-color: vars.$color-background-light;

  > * + * {
    margin-top: 0.7rem;
  }

  ul,
  ol {
    margin-left: 2rem;
  }

  ul {
    list-style: disc;
  }

  li + li {
    margin-top: 0.3rem;
  }
}
