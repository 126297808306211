/** --------------------------------------------------
*
*   NumericField
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/base/utils";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.numeric {

  & label {
    display: inline-block;
    margin-bottom: 0.8rem;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.5;
  }

  & input {
    padding-right: 8.1rem;
    padding-left: 8.1rem;
    text-align: center;

    // Disable the standard input number arrows
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type=number] {
      -moz-appearance: textfield;
    }
  }

  & button {
    position: absolute;
    z-index: 2;
    top: 0.1rem;
    bottom: 0.1rem;
    width: 5.5rem;
    background-color: vars.$color-input-background;
    font-size: 2rem;
    font-weight: 600;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: vars.$color-kournikova;
    }

  }

}

.numericInner {
  position: relative;
}

.numericIncrease {
  right: 0.1rem;
  border-radius: 0 1rem 1rem 0;
  border: none;
  border-left: 0.1rem solid vars.$color-blue-zodiac;
}

.numericDecrease {
  left: 0.1rem;
  border-radius: 1rem 0 0 1rem;
  border: none;
  border-right: 0.1rem solid vars.$color-blue-zodiac;
}