/** --------------------------------------------------
*
*   base
*   TEXT CONTENT
*
*   TABLE OF CONTENTS
*   text content class
*
*   -------------------------------------------------- */


@use "../variables" as vars;
@use "../mixins/text";


/** --------------------------------------------------
*   #text content class
*   -------------------------------------------------- */

.text-content {
  @include text.paragraphText();

  /* margins */
  /* ******* */

  & * + * {
    margin-top: 2.2rem;
  }

  & * + a,
  & * + li,
  & * + dd,
  & * + tr,
  & * + th,
  & * + td {
    margin-top: 0;
  }

  & p + p {
    margin-top: 1.4rem;
  }

  & * + h2,
  & * + h3,
  & * + h4,
  & * + h5,
  & * + h6 {
    margin-top: 2.8rem;
  }

  /* headings */
  /* ******** */

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-family: vars.$font-heading;
    line-height: 1.2;
    font-weight: normal;
  }

  /* font sizes */

  h1 {
    font-size: 4rem;
  }

  h2 {
    font-size: 2.8rem;
  }

  h3 {
    font-size: 2.4rem;
  }

  h4 {
    font-size: 2rem;
  }

  h5 {
    font-size: 1.8rem;
  }

  h6 {
    font-size: 1.6rem;
  }

  /* hyperlinks */
  /* ********** */

  & a {
    font-weight: bold;
    text-decoration: underline;
    word-break: break-word;
    color: vars.$color-brand;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  /* bold */
  /* **** */

  & b,
  & strong {
    font-weight: 600;
  }

  /* tables */
  /* ****** */

  & .text-content-table-wrapper {
    margin: 3.2rem 0;
    overflow-x: auto;

    &:focus {
      box-shadow: 0 0 0 0.2rem white, 0 0 0 0.4rem black;
    }

    .js-focus-visible &:focus:not(.focus-visible) {
      box-shadow: none;
    }

    & table {
      margin: 0;
    }
  }

  & table {
    margin: 3.2rem 0;
    border: none;
    color: inherit;
    border-collapse: separate;
    border-spacing: 0 1rem;

    /* prevent inline table width: */
    width: 100% !important;

    & tr,
    & th,
    & thead,
    & tbody,
    & tfoot,
    & td {
      border: none;
    }

    & th,
    & td {
      padding: 2rem 1.5rem 2rem 3.5rem;
      font-size: 1.2rem;
      text-align: left;
    }

    & th {
      padding-bottom: 1rem;
      font-weight: 600;
      white-space: nowrap;
    }

    & td {
      font-weight: 500;
      background-color: vars.$color-background-light;

      &:first-child {
        border-radius: 1rem 0 0 1rem;
      }

      &:last-child {
        border-radius: 0 1rem 1rem 0;
      }
    }

    & caption {
      text-align: left;
      font-style: italic;
    }

    & .btn-table {
      white-space: nowrap;
    }
  }

  /* lists */
  /* ***** */

  & ul {
    list-style-type: disc;
    margin: 2.2rem 0 2.2rem 1rem;
    padding-left: 4rem;

    & li ul,
    & li ol {
      margin: 1rem 0 1rem 1rem;
      padding-left: 2rem;
    }
  }

  & ol {
    margin: 2.2rem 0 2.2rem 1rem;
    padding-left: 4rem;

    & li ol,
    & li ul {
      margin: 1rem 0 1rem 1rem;
      padding-left: 2rem;
    }
  }

  /* quotes */
  /* ****** */

  & blockquote {
    position: relative;
    font-size: 1.1em;
    font-weight: 600;
    font-style: italic;

    & p {
      &:before {
        content: open-quote;
      }

      &:after {
        content: close-quote;
      }
    }
  }

  /* hr - break */
  /* ********** */

  & hr {
    width: 100%;
    margin: 3.2rem 0;
    border: none;
    border-bottom: 0.2rem solid vars.$color-brand;
  }

  /* iframes */
  /* ******* */

  & iframe {
    max-width: 100%;
  }

  /* special items */
  /* ************* */

  & > *:first-child {
    margin-top: 0;
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  /* external links */
  /* ************** */

  /* example markup:
  <a className="text-content-external-link" target="_blank" rel="noopener noreferrer">
    link text
    <IconExternal />
    <span className='visually-hidden'>visually hidden text</span>
  </a> */

  & .text-content-external-link {
    display: inline-flex;
    flex-flow: row wrap;
    align-items: center;

    & svg {
      margin-left: 0.4em;
      width: 1em;
      height: 1em;
    }
  }
}
