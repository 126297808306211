/** --------------------------------------------------
*
*   PotentialShiftMap
*
*   TABLE OF CONTENTS
*   map
*   tooltip
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/base/utils";


/** --------------------------------------------------
*   #map
*   -------------------------------------------------- */

.mapContainer {
  display: flex;
  flex-flow: row wrap;
  gap: 4rem;

  @include utils.mq(max l) {
    gap: 2rem;
  }
}

.mapContainerMap {
  flex: 1 1 auto;
  min-width: 0;
  display: flex;
  flex-flow: column nowrap;

  @include utils.mq(max m) {
    width: 100%;
  }

  & > * {
    width: 100%;
    flex: 1 1 auto;
  }
}

.mapContainerLegend {
  flex: 0 1 28rem;

  @include utils.mq(max m) {
    width: 100%;
    flex: 0 1 auto;
  }
}




/** --------------------------------------------------
*   #tooltip
*   -------------------------------------------------- */

/* table */
/* ***** */

.PotentialShiftMapTable {
  margin-top: 1rem;
  border-collapse: collapse;
  border: 1px solid vars.$color-black-squeeze;

  tr {
    &:nth-child(even) {
      background-color: vars.$color-black-squeeze;
    }
  }

  th,
  td {
    padding: 0.5rem;
  }
}
