/** --------------------------------------------------
* 
*   base - utils
*   MQ
*
*   INFO
*   Media query mixins to be called directly or with
*   predefined keys configured in [base/breakpoints]
*
*   TABLE OF CONTENTS
*   fallback defaults
*   mq
*   mqset
*
*  -------------------------------------------------- */


@use "../../base/functions";


/** --------------------------------------------------
*   #fallback defaults
*   -------------------------------------------------- */

$mq-breakpoints: () !default;
$mq-presets: () !default;




/** --------------------------------------------------
*   #mq
*   -------------------------------------------------- */

/// Min/max sizes or reference keys always need to come before any additional feature set.
/// To test for multiple queries, wrap each extra query in parentheses ().

/// @param $mq-features:      [string] lookup key from breakpoint list || key/value
///                           OR
///                           [number] value for direct input
/// @param $type:             media type (default: all)
/// @param $breakpoint-list:  Sass map with breakpoints used for lookup

/// Usage examples:
/// @include mq(menu)         all and (min-width: 75em)
/// @include mq(menu xxl)     all and (min-width: 75em) and (max-width: 88.6875em)
/// @include mq(768px menu)   all and (min-width: 48em) and (max-width: 74.9375em)
/// @include mq(max 388px)    all and (max-width: 24.1875em)
/// @include mq(max 388px orientation portrait)
///                           all and (max-width: 24.1875em) and (orientation: portrait)
/// @include mq(768px (orientation portrait))
///                           all and (min-width: 48em), all and (orientation: portrait)

@mixin mq($mq-features, $type: all, $breakpoint-list: $mq-breakpoints) {
  /* Init all the conversion and processing of input and config values */
  $mq-string-map: functions.mq($mq-features, $type, $breakpoint-list);

  /* Use the media query strings from map above to output the CSS rules */
  @media #{map-get($mq-string-map, mq-string)} {
    @content;
  }
}




/** --------------------------------------------------
*   #mqset
*   -------------------------------------------------- */

/// @param $predefined:       [string] lookup key for $mq-predefined list
///
/// Usage example:
/// @include mqset(phone-portrait)
///                           screen and (max-width: 24.1875em) and (orientation: portrait)

@mixin mqset($predefined, $predefined-list: $mq-presets) {
  @if not map-has-key($predefined-list, $predefined) {
    @error "Key '#{$predefined}' not found in available predefined options: #{map-keys($predefined-list)}.";
  }

  $predefined-map: map-get($predefined-list, $predefined);
  
  @media #{map-get($predefined-map, mq-string)} {
    @content;
  }
}
