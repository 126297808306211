/** --------------------------------------------------
*
*   AddEstablishmentPage
*
*   TABLE OF CONTENTS
*   layout
*   general
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/text";


/** --------------------------------------------------
*   #layout
*   -------------------------------------------------- */

/* header */
/* ****** */

.pageHeader {
  margin-bottom: 4rem;
}

.pageTitle {
  @include text.contentTitle();
  margin-bottom: 2rem;
}

/* content */
/* ******* */

.pageContent {
  position: relative;
}

.manualInfoHolder {
  margin-bottom: 2rem;
}

.pageActions {
  margin-top: 4rem;
}

.createMobiscan {
  margin: 0 0 3rem;
}




/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

/* organization */
/* ************ */

.addOrganizationSubtitleHolder {
  margin-bottom: 1.5rem;
}

.addOrganizationSubtitle {
  margin-top: 2rem;
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: bold;
}