/** --------------------------------------------------
*
*   Tab.verticalStyles
*
*   TABLE OF CONTENTS
*   container
*   sidebar
*   content
*
*   -------------------------------------------------- */


@use "sass:color";
@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/layout";
@use "~/src/styles/base/utils";
@use "~/src/styles/mixins/cta";


/** --------------------------------------------------
*   #container
*   -------------------------------------------------- */

.verticalTabContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}




/** --------------------------------------------------
*   #sidebar
*   -------------------------------------------------- */

.verticalTabSidebar {
  flex: 0 1 44%;
  min-width: 0;
  padding-right: 11%;

  @include utils.mq(max m) {
    flex-basis: 100%;
    margin-bottom: 4rem;
    padding-right: 0;
  }

  ul {
    overflow: hidden;
    border-radius: 1rem;

    li + li {
      border-top: 0.1rem solid vars.$color-black-squeeze;
    }
  }

  button {
    @include cta.buttonSkeleton;
    width: 100%;
    background-color: vars.$color-blue-zodiac;
    padding: 1.2rem 1rem 1.2rem 3rem;
    color: vars.$color-white;

    &:hover,
    &:focus {
      background-color: color.adjust(vars.$color-blue-zodiac, $lightness: -5%);
    }

    &:focus-visible,
    &:active {
      text-decoration: underline;
    }

    &:focus-visible {
      outline-offset: -0.2rem;
      box-shadow: inset 0 0 0 0.2rem black, inset 0 0 0 0.4rem white;
    }

    &[aria-current="true"] {
      color: vars.$color-kournikova;
    }

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;

      &:hover,
      &:focus {
        background-color: vars.$color-blue-zodiac;
      }
    }
  }
}




/** --------------------------------------------------
*   #content
*   -------------------------------------------------- */

.verticalTabContent {
  flex: 0 1 55%;
  min-width: 0;

  @include utils.mq(max m) {
    flex-basis: 100%;
  }
}
