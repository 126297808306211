/** --------------------------------------------------
*
*   mixins - text
*   TITLE
*
*   TABLE OF CONTENTS
*   page title
*   content title
*
*  -------------------------------------------------- */


@use "../../variables" as vars;
@use "../../base/utils";


/** --------------------------------------------------
*  #page title
*  -------------------------------------------------- */

@mixin pageTitle() {
  font-size: 4rem;
  line-height: 1.2;

  @include utils.mq(max s) {
    font-size: 2.8rem;
  }
}




/** --------------------------------------------------
*  #content title
*  -------------------------------------------------- */

@mixin contentTitle() {
  font-size: 2.8rem;
  line-height: 1.25;
  font-weight: bold;

  @include utils.mq(max s) {
    font-size: 2.2rem;
  }
}
