/** --------------------------------------------------
*
*   base
*   BASE STYLES
*
*   TABLE OF CONTENTS
*   box-sizing
*   resets
*   defaults
*   focus styles
*   reduced motion
*
*   -------------------------------------------------- */


@use "../variables" as vars;


/** --------------------------------------------------
*   #box-sizing
*   -------------------------------------------------- */

*,
*::after,
*::before {
  box-sizing: border-box;
}




/** --------------------------------------------------
*   #resets
*   -------------------------------------------------- */

body,
div,
section,
article,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
legend,
figure,
figcaption,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}




/** --------------------------------------------------
*   #defaults
*   -------------------------------------------------- */

/* images */

img {
  width: auto;
  max-width: 100%;
  height: auto;
  border-style: none;
}

/* links */

a {
  color: inherit;
}

/* headings */

h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit;
  font-family: vars.$font-heading;
  font-weight: bold;
  line-height: 1.2;
  word-break: break-word;
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 2.8rem;
}

h3 {
  font-size: 2.4rem;
}

h4 {
  font-size: 2rem;
}

h5 {
  font-size: 1.8rem;
}

h6 {
  font-size: 1.6rem;
}

/* lists */

ul {
  list-style: none;
}

/* iframes */

iframe {
  border: none;
}

/* tables */

table {
  width: 100%;
  max-width: 100%;
}

/* hr */

hr {
  box-sizing: content-box;
  overflow: visible;
  display: block;
  height: 0.1rem;
  border: 0;
  border-top: 0.1rem solid #ccc;
  margin: 1em 0;
  padding: 0;
}

/*
* remove the gap between audio, canvas, iframes,
* images, videos and the bottom of their containers
*/

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/* abbr */

abbr[title] {
  text-decoration: none;
  border: none;
}

/* svg */

svg {
  display: block;
  fill: currentColor;
}

/* hidden */

[hidden] {
  display: none;
}

/* text level semantics */

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* address */

address {
  font-style: inherit;
}

/* forms */

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

input[type="file"] {
  max-width: 100%;
}

/* fieldset + legend */

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

/* textarea */

textarea {
  resize: vertical;
  overflow: auto;
}

/* details - summary */

details {
  display: block;
}

summary {
  display: list-item;
}

/* input types */

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

/**
* correct the cursor style of increment and decrement buttons in chrome
*/

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
* correct the odd appearance in chrome and safari
* correct the outline style in safari
*/

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/**
* remove the inner padding in chrome and safari on macOS.
*/

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
* correct the inability to style clickable types in iOS and safari
* change font properties to `inherit` in Safari
*/

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

/* hidden elements */

[hidden] {
  display: none !important;
}




/** --------------------------------------------------
*   #focus styles
*   -------------------------------------------------- */

a:focus,
button:focus,
input[type="button"]:focus,
input[type="submit"]:focus {
  outline: 0.2rem solid black;
  outline-offset: 0.2rem;
}

a:focus:not(:focus-visible),
button:focus:not(:focus-visible),
input[type="button"]:focus:not(:focus-visible),
input[type="submit"]:focus:not(:focus-visible) {
  outline: none;
  box-shadow: none;
}

a:focus-visible,
button:focus-visible,
input[type="button"]:focus-visible,
input[type="submit"]:focus-visible,
[role="region"]:focus-visible {
  outline: 0.2rem solid transparent;
  outline-offset: 0.2rem;
  box-shadow: 0 0 0 0.2rem white, 0 0 0 0.4rem black;
}




/** --------------------------------------------------
*   #reduced motion
*   -------------------------------------------------- */

@media (prefers-reduced-motion: reduce) {

  *,
  *::before,
  *::after {
    animation-duration: 0.001s !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.001s !important;
    scroll-behavior: auto !important;
  }
}
