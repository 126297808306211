/** --------------------------------------------------
*
*   MobiscanUsersPage
*
*   TABLE OF CONTENTS
*   general
*   top actions
*   user form
*   user block
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/base/utils";
@use "~/src/styles/mixins/cta";
@use "~/src/styles/mixins/layout";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.pageContent {
  margin-top: vars.$content-spacing-vertical;
  margin-bottom: vars.$content-spacing-vertical;
}




/** --------------------------------------------------
*   #top actions
*   -------------------------------------------------- */

.topActions {
  position: relative;
  margin-bottom: 3rem;
}

.topActionsInner {
  @include layout.contentWidth();
}

.topActionItems {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  margin: 0 -1rem -2rem;

  @include utils.mq(max m) {
    justify-content: flex-start;
  }
}

.topActionItem {
  padding: 0 1rem 2rem;
}




/** --------------------------------------------------
*   #user form
*   -------------------------------------------------- */

.userForm {}

/* user items */
/* ********** */

.userItem {
  padding: 5rem 0;
}

.userItem + .userItem {
  border-top: 0.2rem solid vars.$color-geyser;
}

.userItemInner {
  @include layout.contentWidth();
  display: flex;
  flex-flow: row wrap;

  @include utils.mq(max m) {
    flex-direction: column;
  }
}

.userItemHeader {
  flex: 0 1 30rem;
  width: 30rem;
  padding-right: 2rem;

  @include utils.mq(max m) {
    flex-basis: 100%;
    width: 100%;
    padding-right: 0;
  }
}

.userItemContent {
  flex: 0 1 calc(100% - 30rem);
  width: calc(100% - 30rem);

  @include utils.mq(max m) {
    flex-basis: 100%;
    width: 100%;
    margin-top: 2rem;
  }
}

/* form actions */
/* ************ */

.userFormActions {
  margin-top: 1rem;
}

.userFormActionsInner {
  @include layout.contentWidth();
}




/** --------------------------------------------------
*   #user block
*   -------------------------------------------------- */

.userName {
  margin-bottom: 0.5rem;
  font-weight: 900;
  font-size: 2rem;
}

.userFunction,
.userMail,
.userPhone {
  font-size: 1.4rem;
  line-height: 1.5;
  margin-bottom: 0.5rem;
  word-break: break-word;

  & a {
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

.userRemoveBtn {
  @include cta.buttonSkeleton();
  display: block;
  font-size: 1.4rem;
  line-height: 1.5;
  font-weight: 600;
  text-decoration: underline;
  color: vars.$color-monza;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}
