/** --------------------------------------------------
*
*   NoEstablishments
*
*   TABLE OF CONTENTS
*   general
*   info
*   contact
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/text";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.subtitle {
  @include text.contentTitle();
  margin-bottom: 2rem;
}




/** --------------------------------------------------
*   #info
*   -------------------------------------------------- */

.info {
  margin-top: 3.4rem;
}




/** --------------------------------------------------
*   #contact
*   -------------------------------------------------- */

.contact {
  margin-top: 3.4rem;
}

.contactActions {
  margin-top: 2rem;
}
