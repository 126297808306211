/** --------------------------------------------------
*
*   RegistrationDetailPage
*
*   TABLE OF CONTENTS
*   intro
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/layout";
@use "~/src/styles/mixins/cta";
@use "~/src/styles/mixins/text";
@use "~/src/styles/base/utils";


/** --------------------------------------------------
*   #intro
*   -------------------------------------------------- */

.introBlock {
  padding: 2rem 0 4rem;

  @include utils.mq(max l) {
    padding: 0;
  }
}

.introTitle {
  @include text.pageTitle();
  margin-bottom: 2rem;
}

.introDescription {
  font-size: 1.6rem;
  line-height: 1.75;
}


.content {
  @include layout.contentWidth();
  margin-top: vars.$content-spacing-vertical;
  margin-bottom: vars.$content-spacing-vertical;

  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.pageTitle {
  @include text.pageTitle();
  margin-bottom: 4rem;
}

.contentMain {
  flex-grow: 1;
  width: calc(100% - 25rem - 4rem);
}

.contentAside {
  width: 25rem;
}

.organisationInfo {
  h3 {
    margin-bottom: 2rem;
  }

  dl {
    display: grid;
    row-gap: 1rem;
    column-gap: 2rem;
    grid-template-columns: max-content auto;
    margin-bottom: 3rem;
  }

  dt {
    grid-column-start: 1;
    width: 25rem;
    font-weight: 600;
  }

  dd {
    grid-column-start: 2;
  }
}
