/** --------------------------------------------------
*
*   CustomEstablishmentInput
*
*   TABLE OF CONTENTS
*   general
*   organization
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/base/utils";
@use "~/src/styles/mixins/cta";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.addButtonWrapper {
  margin: 1.5rem 0 3rem;
}




/** --------------------------------------------------
*   #organization
*   -------------------------------------------------- */

.itemOrganization {
  display: flex;
  flex-flow: row wrap;
}

.itemOrganization + .itemOrganization {
  margin-top: 1.5rem;
}

.itemOrganization + .addOrganizationSubtitleHolder {
  margin-top: 3rem;
}

.itemOrganizationForm {
  flex: 1 1 calc(100% - 4.6rem);
  width: calc(100% - 4.6rem);
}

.itemOrganizationDelete {
  flex: 0 0 4.6rem;
  width: 4.6rem;

  margin-top: 0.2rem;
}

.addOrganization {}

.itemOrganizationForm {
  padding: 2rem;
  background-color: vars.$color-background-light;
  border: 0.1rem solid vars.$color-brand;
  border-radius: 1rem;
}

.formTitle {
  margin-bottom: 1rem;
  color: vars.$color-brand;
  font-weight: 600;
}

/* address */
/* ******* */

.formAddAddress {

  & label {
    font-size: 1.4rem;
  }

  & input {
    background-color: vars.$color-white;
  }
}

.formRow {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -1rem -1rem;
}

.formRow + .formRow {
  margin-top: 1rem;
}

.FormItem {
  flex: 1 1 auto;
  width: 100%;
  padding: 0 1rem 1rem;
}

.FormItem[data-width="25"] {
  width: 25%;

  @include utils.mq(max m) {
    width: 100%;
  }
}

.FormItem[data-width="50"] {
  width: 50%;

  @include utils.mq(max m) {
    width: 100%;
  }
}

/* remove */
/* ****** */

.removeOrganizationButton {
  @include cta.buttonSkeleton();
  padding: 1.5rem;
  color: vars.$color-brand;

  svg {
    width: 1.6rem;
    height: 1.8rem;
  }
}
