/** --------------------------------------------------
*
*   EditQuestionnairePage
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/base/utils";
@use "~/src/styles/mixins/text";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.questionnaireHeader {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5.5rem;
}

.questionnaireHeaderTitle {
  @include text.pageTitle();
}