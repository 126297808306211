/** --------------------------------------------------
*
*   MobiscanUsersPage
*
*   TABLE OF CONTENTS
*   general
*   top actions
*   user form
*   user block
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/base/utils";
@use "~/src/styles/mixins/cta";
@use "~/src/styles/mixins/layout";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.pageContent {
  margin-top: vars.$content-spacing-vertical;
  margin-bottom: vars.$content-spacing-vertical;
}




/** --------------------------------------------------
*   #top actions
*   -------------------------------------------------- */

.topActions {
  position: relative;
  margin-bottom: 3rem;
}

.topActionsInner {
  @include layout.contentWidth();
}

.topActionItems {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  margin: 0 -1rem -2rem;

  @include utils.mq(max m) {
    justify-content: flex-start;
  }
}

.topActionItem {
  padding: 0 1rem 2rem;
}

/** --------------------------------------------------
*   #overview
*   -------------------------------------------------- */

.overview {
  margin-top: vars.$content-spacing-vertical;
  margin-bottom: vars.$content-spacing-vertical;
}

.overviewInner {
  @include layout.contentWidth();
}

/** --------------------------------------------------
*   #button
*   -------------------------------------------------- */

.deleteButton {
  @include cta.ctaSecondary();

  padding: 0.7rem 3rem;
  border: 0;
}