/** --------------------------------------------------
*
*   components - charts
*   CHART HOLDER
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "../../variables" as vars;
@use "../../base/utils";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.chart-scroll-outer {
  overflow-x: auto;
}

.chart-scroll-inner {
  min-width: 80rem;
}
