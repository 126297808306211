/** --------------------------------------------------
*
*   MessageBox
*
*   TABLE OF CONTENTS
*   general
*   display variations
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/base/utils";
@use "~/src/styles/mixins/cta";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.box {
  display: flex;
  flex-flow: row nowrap;

  margin-bottom: 3.4rem;
  padding: 2.5rem 3rem;
  background-color: vars.$color-white;
  color: vars.$color-blue-zodiac;
  border: 0.1rem solid vars.$color-blue-zodiac;
  border-radius: 1rem;
  font-weight: 600;

  @include utils.mq(max s) {
    flex-flow: column wrap;
  }
}

/* icon block */
/* ********** */

.iconHolder {
  flex: 0 0 auto;
  min-width: 0;
  margin-right: 3rem;

  @include utils.mq(max s) {
    margin-bottom: 2rem;
  }

  & svg {
    width: 4rem;
    height: auto;
  }
}

/* content block */
/* ************* */

.contentHolder {
  flex: 1 1 auto;
  min-width: 0;
}

.title {
  font-size: 2.4rem;
  line-height: 1.2;
  font-weight: bold;

  @include utils.mq(max s) {
    font-size: 2.2rem;
  }
}

.message {}

.title + .message {
  margin-top: 0.6rem;
}

.contentActions {
  margin-top: 0.6rem;

  & button {
    @include cta.buttonSkeleton();
  }

  & button,
  & a {
    color: vars.$color-matisse;
    text-decoration: underline;
    font-weight: inherit;
    line-height: inherit;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}




/** --------------------------------------------------
*   #display variations
*   -------------------------------------------------- */

/* success */
/* ******* */

.box[data-type="success"] {
  color: vars.$color-cactus;
  border-color: vars.$color-cactus;
}

/* no-border */
/* ********* */

.box[data-displaytype="no-border"] {
  border: 0;
  margin-bottom: 0;
}

/* minimal */
/* ******* */

.box[data-displaytype="minimal"] {
  padding: 1.5rem 2rem;
  border-color: vars.$color-geyser;
  background-color: vars.$color-background-light;
  font-size: 1.4rem;
  margin-bottom: 0;
}
