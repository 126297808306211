/** --------------------------------------------------
*
*   EnterpriseTile
*
*   TABLE OF CONTENTS
*   general
*   header
*   actions
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/base/utils";
@use "~/src/styles/mixins/cta";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.tile {
  position: relative;
  color: vars.$color-text;
  background-color: vars.$color-white;
  border: 0.1rem solid vars.$color-brand;
  border-radius: 1rem;
  overflow: hidden;
}




/** --------------------------------------------------
*   #header
*   -------------------------------------------------- */

.header {
  padding: 2rem 3.2rem;

  @include utils.mq(max s) {
    padding: 2rem;
  }
}

.title {
  font-size: 2.5rem;
  line-height: 1.28;
  font-weight: bold;
}

.info {
  font-size: 1.4rem;
  line-height: 1.5;
  font-weight: 600;
}




/** --------------------------------------------------
*   #actions
*   -------------------------------------------------- */

.actions {
  border-top: 0.1rem solid vars.$color-brand;
}

.actionItem {}

.actionItem + .actionItem {
  border-top: 0.1rem solid vars.$color-brand;
}

.actionButton {
  @include cta.buttonSkeleton();
  display: block;
  width: 100%;
  transition: background-color 0.15s ease-out;
  padding: 1.8rem 3.2rem;
  font-size: 1.4rem;
  line-height: 1.5;
  font-weight: 600;
  text-align: center;
  text-decoration: underline;
  color: vars.$color-blue-zodiac;

  @include utils.mq(max s) {
    padding: 1.4rem 2rem;
  }

  &:hover,
  &:focus {
    background-color: vars.$color-background-light;
  }

  &:focus-visible {
    outline-offset: -0.4rem;
    box-shadow: inset 0 0 0 0.2rem white, inset 0 0 0 0.4rem black;
  }
}

.actionItemErrorContainer {
  padding: 0 2rem;
}
