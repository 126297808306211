/** --------------------------------------------------
*
*   Tab.headerStyles
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/cta";
@use "~/src/styles/base/utils";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.headerTabsWrapper {

  & ul {
    display: flex;
    flex-flow: row nowrap;

    @include utils.mq(max l) {
      flex-direction: column;
    }
  }

  & li {
    flex: 1 1 auto;
    min-width: 0;
    display: flex;
    flex-flow: column nowrap;
  }

  & button {
    @include cta.buttonSkeleton;
    flex: 1 1 auto;
    width: 100%;

    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    padding: 2rem;

    border: 0.1rem solid vars.$color-brand;
    border-right: 0;
    color: vars.$color-blue-zodiac;
    background-color: vars.$color-white;
    font-size: 1.2rem;
    line-height: 1.2;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;

    @include utils.mq(max l) {
      border-bottom: none;
      border-right: 0.1rem solid vars.$color-brand;
    }

    &:hover,
    &:focus {
      background-color: vars.$color-background-light;
    }

    &:focus-visible,
    &:active {
      text-decoration: underline;
    }

    &:focus-visible {
      outline-offset: -0.2rem;
      box-shadow: inset 0 0 0 0.2rem white, inset 0 0 0 0.4rem black;
    }

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }

    &[aria-current="true"] {
      background-color: vars.$color-kournikova;

      @include utils.mqset(highcontrast) {
        text-decoration: underline;
      }

      @include utils.mqset(forcedcolors-more) {
        text-decoration: underline;
      }
    }
  }

  & li:first-child button {
    border-radius: 1rem 0 0 1rem;

    @include utils.mq(max l) {
      border-radius: 1rem 1rem 0 0;
    }
  }

  & li:last-child button {
    border-right: 0.1rem solid vars.$color-brand;
    border-radius: 0 1rem 1rem 0;

    @include utils.mq(max l) {
      border-bottom: 0.1rem solid vars.$color-brand;
      border-radius: 0 0 1rem 1rem;
    }
  }
}
