/** --------------------------------------------------
* 
*   mixins - text
*   PARAGRAPH
*
*   TABLE OF CONTENTS
*   paragraph text
*
*  -------------------------------------------------- */


@use "../../variables" as vars;
@use "../../base/utils";


/** --------------------------------------------------
*  #paragraph text
*  -------------------------------------------------- */

@mixin paragraphText() {
  font-size: 1.6rem;
  line-height: 1.5;

  @include utils.mq(max s) {
    font-size: 1.4rem;
  }
}
