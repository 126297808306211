/** --------------------------------------------------
*
*   RegistrationActions
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/cta";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.approve {
  @include cta.ctaPrimary();
  width: 100%;
  margin-bottom: 1.4rem;
}

.reject {
  @include cta.ctaPrimaryInverse();
  width: 100%;
}
