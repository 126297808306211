/** --------------------------------------------------
*
*   InputPage
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/base/utils";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.header {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;

  margin-top: -1rem;
  margin-bottom: vars.$content-spacing-vertical;

  h1 {
    margin-right: 3rem;
  }

  h1,
  button {
    margin-top: 1rem;
  }
}
