/** --------------------------------------------------
*
*   AlreadRegistered
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.askAccessMessage {
  margin: 4rem 0 0 0;
}