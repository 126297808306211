/** --------------------------------------------------
*
*   Tab.subStyles
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/cta";
@use "~/src/styles/base/utils";
@use "~/src/styles/mixins/layout";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.subTabsWrapper {
  @include layout.contentWidth();

  & ul {
    display: flex;
    flex-flow: row nowrap;
    margin: 0 -0.4rem;
    overflow-x: auto;
  }

  & li {
    flex: 0 0 auto;
    min-width: 0;
    margin: 0 0.4rem;

    display: flex;
    flex-flow: column nowrap;
  }

  & button {
    @include cta.buttonSkeleton;
    flex: 1 1 auto;
    width: 100%;
    display: block;
    padding: 1.3rem 2.6rem 1.8rem 2.6rem;

    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    font-size: 1.2rem;
    line-height: 1.3;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border-top: 0.5rem solid transparent;

    &:hover,
    &:focus {
      background-color: vars.$color-catskill-white;
    }

    &:focus-visible,
    &:active {
      text-decoration: underline;
    }

    &:focus-visible {
      outline-offset: -0.2rem;
      box-shadow: inset 0 0 0 0.2rem white, inset 0 0 0 0.4rem black;
    }

    &[aria-current="true"] {
      background-color: vars.$color-catskill-white;
      border-top-color: vars.$color-matisse;

      @include utils.mqset(highcontrast) {
        text-decoration: underline;
      }

      @include utils.mqset(forcedcolors-more) {
        text-decoration: underline;
      }
    }

    & img {
      display: block;
      height: 6rem;
      width: 100%;
      object-fit: contain;
    }

    & img + span {
      margin-top: 1rem;
    }
  }
}

.subPanelsWrapper {
  background-color: vars.$color-catskill-white;
  padding-top: vars.$content-spacing-vertical;
  padding-bottom: vars.$content-spacing-vertical;
}
