/** --------------------------------------------------
*
*   ReportPage
*
*   TABLE OF CONTENTS
*   intro
*   intro: actions box
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/cta";
@use "~/src/styles/base/utils";


/** --------------------------------------------------
*   #intro
*   -------------------------------------------------- */

.introBlock {
  padding-bottom: 4rem;

  & .inner::after {
    bottom: -2rem;
  }
}

.reportTitle {
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 3rem;

  @include utils.mq(max s) {
    font-size: 3.2rem;
  }
}

.reportName {
  display: block;
}

.reportDate {
  display: block;
  color: vars.$color-kournikova;
}

.reportAddress {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 1.5rem;

  & svg {
    flex: 0 0 auto;
    min-width: 0;
    width: 1.6rem;
    height: auto;
    margin-right: 1rem;
  }

  & span {
    flex: 1 1 auto;
    min-width: 0;
    font-size: 1.8rem;
    font-weight: 600;
  }
}




/** --------------------------------------------------
*   #intro: actions box
*   -------------------------------------------------- */

.actionsBox {
  width: 100%;
  max-width: 46rem;
  color: vars.$color-text;
  background-color: vars.$color-white;
  border: 0.1rem solid vars.$color-brand;
  border-radius: 1rem;
  overflow: hidden;
  margin-left: auto;

  @include utils.mq(max l) {
    max-width: 100%;
  }

  @media print {
    display: none;
  }
}

/* actions */
/* ******* */

.actionsBoxActions {
  width: 100%;
}

.actionsBoxActions {}

.actionsBoxAction {}

.actionsBoxAction + .actionsBoxAction {
  border-top: 0.1rem solid vars.$color-brand;
}

.actionsBoxActionLink {
  @include cta.buttonSkeleton();
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  text-decoration: none;
  transition: background-color 0.15s ease-out;
  padding: 1.4rem 3.2rem;
  font-size: 1.4rem;
  line-height: 1.5;
  font-weight: 600;

  &:hover,
  &:focus {
    background-color: vars.$color-background-light;
  }

  &:focus,
  &:active {
    text-decoration: underline;
  }

  &:focus-visible {
    outline-offset: -0.4rem;
    box-shadow: inset 0 0 0 0.2rem white, inset 0 0 0 0.4rem black;
  }

  & > img {
    flex: 0 0 auto;
    min-width: 0;
    display: block;
    width: 3.2rem;
    height: 3.2rem;
    margin-right: 1.8rem;

    @include utils.mq(max xs) {
      width: 2rem;
      height: 2rem;
    }
  }
}

.actionsBoxActionLinkText {
  flex: 1 1 auto;
  min-width: 0;
  padding-right: 1rem;
  word-break: break-word;
}

.actionsBoxActionIcon {
  flex: 0 0 auto;
  min-width: 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  color: vars.$color-brand;
  width: 2.6rem;
  height: 2.6rem;

  & svg {
    display: block;
    width: 1.4rem;
    height: 1.4rem;
  }
}

.actionsBoxActionIconCircled {
  color: vars.$color-brand;
  border: 0.2rem solid;
  border-radius: 50%;
  width: 2.6rem;
  height: 2.6rem;
  background-color: vars.$color-background-light;
  transition: background-color 0.15s ease-out;

  & svg {
    display: block;
    width: 1.4rem;
    height: 1.4rem;
  }
}

.actionsBoxActionLink:hover .actionsBoxActionIconCircled,
.actionsBoxActionLink:focus .actionsBoxActionIconCircled {
  background-color: vars.$color-white;
}

/* bottom */
/* ****** */

.actionsBoxRemoveButton {
  @include cta.buttonSkeleton();
  width: 100%;
  border-top: 0.1rem solid vars.$color-blue-zodiac;
  padding: 2rem 3.2rem;
  color: vars.$color-brand;
  font-size: 1.2rem;
  line-height: 1.5;
  text-decoration: underline;
  text-align: center;

  &:focus,
  &:hover {
    background-color: vars.$color-black-squeeze;
  }

  &:focus-visible {
    outline-offset: -0.4rem;
    box-shadow: inset 0 0 0 0.2rem white, inset 0 0 0 0.4rem black;
  }
}