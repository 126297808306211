/** --------------------------------------------------
*
*   UserPage
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/text";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.userInfo {
  @include text.contentTitle();
  margin-bottom: 2rem;
}

.userInfoTitle {
  margin-bottom: 2rem;
}
