/** --------------------------------------------------
*
*   components - forms
*   FORM SELECT
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "../../variables" as vars;
@use "../../base/utils";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

select {
  max-width: 100%;

  padding: 1.5rem 2.6rem;
  background-color: vars.$color-input-background;
  border: 0.1rem solid vars.$color-input-border;
  border-radius: 1rem;
  box-shadow: none;

  font-family: vars.$font-basic;
  font-size: 1.6rem;
  line-height: 1.5;
  color: vars.$color-input-foreground;
  transition: border-color 0.15s ease-out;

  &:focus {
    border-color: vars.$color-input-border;
    box-shadow: 0 0 0 0.1rem black;
    outline: 0.2rem solid transparent;
    outline-offset: 0.2rem;
  }

  &[disabled] {
    opacity: 0.5;
  }
}
