/** --------------------------------------------------
*
*   BackButton
*
*   TABLE OF CONTENTS
*   general
*   display variation: box
*   display variation: text
*   display variation: inline
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/base/utils";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.link {

  @media print {
    display: none;
  }
}




/** --------------------------------------------------
*   #display variation: box
*   -------------------------------------------------- */

.link[data-displaytype="box"] {
  margin-bottom: 3.4rem;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 1.3rem 2.6rem;
  border-radius: 1rem;

  background: linear-gradient(180deg, rgba(45, 177, 226, 0.15) 0%, rgba(255, 255, 255, 0) 100%);
  color: vars.$color-white;
  text-decoration: none;
  font-weight: 600;

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  & svg {
    flex: 0 0 auto;
    min-width: 0;
    width: 1.4rem;
    height: 1.4rem;
    margin-right: 1.4rem;
    fill: vars.$color-curious-blue;
  }
}




/** --------------------------------------------------
*   #display variation: text
*   -------------------------------------------------- */

.link[data-displaytype="text"] {
  margin-bottom: 3.4rem;

  display: inline-flex;
  align-items: center;
  padding: 1rem 0;
  color: vars.$color-text--light;
  font-weight: 600;
  font-size: 1.4rem;
  text-decoration: underline;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  & svg {
    flex: 0 0 auto;
    min-width: 0;
    width: 1.4rem;
    height: 1.4rem;
    margin-right: 1rem;
  }
}




/** --------------------------------------------------
*   #display variation: inline
*   -------------------------------------------------- */

.link[data-displaytype="inline"] {
  display: inline-flex;
  align-items: center;
  padding: 1rem 0;
  color: vars.$color-text--light;
  font-weight: 600;
  font-size: 1.4rem;
  text-decoration: underline;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  & svg {
    flex: 0 0 auto;
    min-width: 0;
    width: 1.4rem;
    height: 1.4rem;
    margin-right: 1rem;
  }
}
