/** --------------------------------------------------
*
*   EnterpriseFilter
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/base/utils";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.form {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  margin: 0 -1rem -2rem;
}

.formItem {
  flex: 0 1 auto;
  min-width: 0;
  width: 50%;
  padding: 0 1rem 2rem;

  @include utils.mq(max m) {
    width: 100%;
  }
}
