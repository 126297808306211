/** --------------------------------------------------
*
*   MobiscanOverview
*
*   TABLE OF CONTENTS
*   general
*   header
*   header filter
*   header actions
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/layout";
@use "~/src/styles/base/utils";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.container {
  position: relative;
}




/** --------------------------------------------------
*   #header
*   -------------------------------------------------- */

.header {
  position: relative;
  margin-bottom: 8rem;

  @include utils.mq(max l) {
    margin-bottom: 6rem;
  }
}

/* layout */
/* ****** */

.headerInner {
  @include layout.contentWidth();
  display: flex;
  flex-flow: row wrap;
}

.headerBlockFilter {
  flex: 0 1 auto;
  min-width: 0;
  width: 50%;

  @include utils.mq(max l) {
    width: 100%;
  }
}

.headerBlockFilterInner {
  margin-right: 3rem;

  @include utils.mq(max l) {
    margin-right: 0;
  }
}

.headerBlockActions {
  flex: 0 1 auto;
  min-width: 0;
  width: 50%;
  align-self: flex-end;
  margin-top: 3.4rem;

  @include utils.mq(max l) {
    width: 100%;
  }
}

.headerBlockActionsInner {
  margin-left: 5.5rem;

  @include utils.mq(max l) {
    margin-left: 0;
  }
}




/** --------------------------------------------------
*   #header filter
*   -------------------------------------------------- */

.headerFilter {
  position: relative;
  z-index: 2;
  border: 0.1rem solid vars.$color-brand;
  border-radius: 1rem;
  background-color: vars.$color-white;
  margin-top: -9rem;
  /* this negative margin-top is a magic number, the supposed height of the "header filter title block */
}

.headerFilterTitleBlock {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  background-color: vars.$color-kournikova;
  border-bottom: 0.1rem solid vars.$color-brand;
  padding: 2.4rem 2rem;
  border-radius: 1rem 1rem 0 0;

  & svg {
    width: 4rem;
    height: 4rem;
    margin-right: 1.6rem;
  }
}

.headerFilterTitle {
  font-size: 2.8rem;
  line-height: 1.26;
  font-weight: bold;

  @include utils.mq(max s) {
    font-size: 2.2rem;
  }
}

.headerFilterContentBlock {
  padding: 3rem 4rem 3.4rem;

  @include utils.mq(max l) {
    padding: 3rem 3.2rem;
  }
}

.headerFilterContentBlockText {
  font-size: 1.6rem;
  line-height: 1.75;
  margin-bottom: 2.4rem;
}




/** --------------------------------------------------
*   #header actions
*   -------------------------------------------------- */

.headerBlockActionsTitle {
  font-size: 2rem;
  line-height: 1.2;
}

.headerBlockActionsDescription {
  font-size: 1.6rem;
  line-height: 1.75;
  margin-top: 0.8rem;
}

.headerBlockActionsLinks {
  margin-top: 2.8rem;
}