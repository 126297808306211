/** --------------------------------------------------
*
*   TimetableTab
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/base/utils";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.timetableTitle {
  margin-bottom: 3rem;
  padding-bottom: 2rem;
  border-bottom: 0.2rem solid vars.$color-geyser;
}

.timetableContent {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;

  margin-bottom: 2rem;

  & svg {
    flex: 0 0 3.5rem;
    width: 2rem;
    min-width: 0;
    height: auto;
    padding-right: 1.5rem;
  }

  & span {
    flex: 0 1 calc(100% - 4rem);
    min-width: 0;

    font-weight: 600;
  }
}

.timetableOptions {
  margin-bottom: 4rem;
  padding-bottom: 4rem;
  border-bottom: 0.2rem solid vars.$color-geyser;
}

.timetableActions {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;

  > div {
    margin-bottom: 0;
  }

  button {
    width: 65%;

    @include utils.mq(max s) {
      width: 100%;
    }
  }
}
