/** --------------------------------------------------
*
*   SummaryTab - intro
*
*   TABLE OF CONTENTS
*   general
*   text block
*   graphic block
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/layout";
@use "~/src/styles/mixins/text";
@use "~/src/styles/base/utils";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.intro {
  margin-top: vars.$content-spacing-vertical;
  margin-bottom: 4rem;

  @include utils.mq(max s) {
    margin-bottom: 2rem;
  }
}

.introInner {
  @include layout.contentWidth();

  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

/* text block */
/* ********** */

.textBlock {
  flex: 0 1 55%;
  min-width: 0;
  padding-right: 5%;

  @include utils.mq (max l) {
    flex-basis: 100%;
    margin-bottom: 2rem;
    padding-right: 0;
  }
}

/* graphic block */
/* ************* */

.graphicBlock {
  flex: 0 1 45%;
  min-width: 0;
  padding-left: 2%;

  @include utils.mq (max l) {
    flex-basis: 100%;
    padding-left: 0;
  }
}




/** --------------------------------------------------
*   #text block
*   -------------------------------------------------- */

/* title */
/* ***** */

.title {
  @include text.pageTitle();
  margin-bottom: 2rem;
}

/* text */
/* **** */

.text {
  font-size: 1.6rem;
  line-height: 1.75;
}




/** --------------------------------------------------
*   #graphic block
*   -------------------------------------------------- */

.itemSummary {
  position: relative;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  @include utils.mq(max l) {
    width: 100%;
    max-width: 49rem;
    margin-right: auto;
    margin-left: auto;
  }

  @include utils.mq(max s) {
    flex-flow: column wrap;
  }
}

.itemSummaryBlock {
  position: relative;
  width: 50%;
  right: -8%;

  @include utils.mq(max l) {
    right: -4%;
  }

  @include utils.mq(max s) {
    width: 100%;
    right: auto;
  }
}

.itemSummaryBlock + .itemSummaryBlock {
  z-index: 5;
  right: 0;

  @include utils.mq(max l) {
    left: -4%;
  }

  @include utils.mq(max s) {
    padding-top: 3.5rem;
    right: auto;
    left: auto;
  }
}

.itemSummaryContent {
  padding: 2rem 3rem;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.5;
  text-align: center;

  @include utils.mq(max s) {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

/* circle layout */
/* ************* */

.itemSummaryCircle {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  /* keep aspect ratio: */
  &::before {
    content: "";
    float: left;
    padding-bottom: 100%;
  }

  &::after {
    clear: left;
    content: " ";
    display: table;
  }

  @include utils.mq(max s) {

    &::before,
    &::after {
      display: none;
    }
  }
}

// use an absolutely positioned background
// element to prevent elongated circles, but
// allow elongated circles if there's too much
// content:
.itemSummaryCircleBackground {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: vars.$color-background-light;
  border: 0.1rem solid vars.$color-brand;
  border-radius: 50%;

  @include utils.mq(max s) {
    border-radius: 1rem;
  }
}

.itemSummaryCircle[data-type="potential"] .itemSummaryCircleBackground {
  background-color: vars.$color-white;
}

.itemSummaryCircleArrow {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 1.5rem;
  transform: translateY(-50%);

  @include utils.mq(max xl) {
    left: 1rem;
  }

  @include utils.mq(max s) {
    top: -3rem;
    left: 50%;
    transform: translate(-50%, 0);
  }

  & svg {
    width: 2rem;
    height: auto;

    @include utils.mq(max s) {
      width: 1.5rem;
      transform: rotate(90deg);
    }
  }
}

.itemSummaryCircleContent {
  position: relative;
  z-index: 2;
  text-align: center;

  @include utils.mq(max s) {
    padding: 1rem;
  }
}

.itemSummaryCircleValue {
  font-weight: bold;
  font-size: 4.8rem;
  line-height: 1.16;
  padding-top: 0.5rem;

  @include utils.mq(max m) {
    font-size: 3.6rem;
  }

  @include utils.mq(max s) {
    font-size: 2.8rem;
  }
}

.itemSummaryCircleDescription {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;

  padding-top: 0.5rem;
  color: vars.$color-matisse;
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 1.28;

  @include utils.mq(max m) {
    font-size: 2rem;
  }

  & svg {
    width: 2.7rem;
    height: auto;

    @include utils.mq(max s) {
      width: 2rem;
    }
  }
}
