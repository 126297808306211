/** --------------------------------------------------
*
*   RegisterForm
*
*   TABLE OF CONTENTS
*   general
*   legal block
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/text";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.data {
  margin-bottom: vars.$site-padding;
  padding-bottom: vars.$site-padding;
  border-bottom: 0.2rem solid vars.$color-geyser;
}

.dataTitle {
  @include text.contentTitle();
  margin-bottom: 2rem;
}




/** --------------------------------------------------
*   #legal block
*   -------------------------------------------------- */

.legal {
  margin-top: 3.4rem;
  padding-top: 3.4rem;
  border-top: 0.2rem solid vars.$color-geyser;
}

.legalItems {
  // margin: 0.6rem -1rem -2rem 3.2rem;
  display: flex;
  flex-flow: row wrap;
}

.legalItem {
  // padding: 0 1rem 2rem;
}