/** --------------------------------------------------
*
*   EstablishmentsSelector
*
*   TABLE OF CONTENTS
*   general
*   organization
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/base/utils";
@use "~/src/styles/mixins/cta";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.addButtonWrapper {
  margin: 1.5rem 0 3rem;
}



/** --------------------------------------------------
*   #organization
*   -------------------------------------------------- */

.itemOrganization {
  display: flex;
  flex-flow: row wrap;
}

.itemOrganization+.itemOrganization {
  margin-top: 1.5rem;
}

.itemOrganization+.addOrganizationSubtitleHolder {
  margin-top: 3rem;
}

.itemOrganizationChoice {
  flex: 1 1 calc(100% - 4.6rem);
  width: calc(100% - 4.6rem);
}

.itemOrganizationDelete {
  flex: 0 0 4.6rem;
  width: 4.6rem;

  margin-top: 0.2rem;
}

.addOrganization {}

.removeOrganizationButton {
  @include cta.buttonSkeleton();
  padding: 1.5rem;
  color: vars.$color-brand;

  svg {
    width: 1.6rem;
    height: 1.8rem;
  }
}
