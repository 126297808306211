/** --------------------------------------------------
*
*   InfrastructureTab
*
*   TABLE OF CONTENTS
*   general
*   categories
*   section
*   section item
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/layout";
@use "~/src/styles/mixins/text";
@use "~/src/styles/base/utils";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.infrastructure {
  padding-top: vars.$content-spacing-vertical;
  padding-bottom: vars.$content-spacing-vertical;
}

.infrastructureInner {
  @include layout.contentWidth();
}




/** --------------------------------------------------
*   #categories
*   -------------------------------------------------- */

.infrastructureCategory + .infrastructureCategory {
  margin-top: 6rem;
}

.infrastructureCategoryTitle {
  @include text.pageTitle();
  margin-bottom: 1rem;
}

.infrastructureCategorySubtitle {
  margin-bottom: 4rem;
}




/** --------------------------------------------------
*   #section
*   -------------------------------------------------- */

.section {
  margin-bottom: 4rem;
}

/* title */
/* ***** */

.sectionTitle {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-bottom: 2rem;

  & img {
    flex: 0 0 auto;
    min-width: 0;
    margin-right: 2.4rem;
    width: 6.4rem;
    height: 6.4rem;
    object-fit: contain;
  }
}

/* list */
/* **** */

.sectionList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 1rem;
  column-gap: 2.4rem;

  @include utils.mq(max l) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.sectionListItem {
  min-width: 0;
  background-color: vars.$color-background-light;
  border-radius: 1rem;
}

/* item */
/* **** */

.sectionItem {
  display: flex;
  column-gap: 2.4rem;
  height: 100%;
}

.sectionItemStatusWrapper {
  padding: 1.6rem 0 1.6rem 3.2rem;

  @include utils.mq(max s) {
    padding: 1.4rem 0 1.4rem 2rem;
  }
}

.sectionItemStatus {
  flex: 0 0 auto;
  min-width: 0;

  display: flex;
  flex: 0 0 2.4rem;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 0.5rem;
  border: 0.2rem solid vars.$color-blue-zodiac;
  position: relative;
}

.sectionItemStatusNo {
  // Disabled cross icon for https://makeitfly.atlassian.net/browse/PWVM-634
  
  // &::before,
  // &::after {
  //   content: '';
  //   width: 0;
  //   height: 1.4rem;
  //   border-left: 0.2rem solid vars.$color-blue-zodiac;
  //   position: absolute;
  //   top: 0.3rem;
  //   left: 0.9rem;
  // }

  // &::before {
  //   transform: rotate(45deg);
  // }

  // &::after {
  //   transform: rotate(-45deg);
  // }
}

.sectionItemStatusFuture {}

.sectionItemStatusYes {
  &::before {
    content: '';
    width: 1.3rem;
    height: 0.8rem;
    border: solid vars.$color-blue-zodiac;
    border-width: 0 0 0.2rem 0.2rem;
    transform: rotate(-45deg);
    position: absolute;
    top: 0.4rem;
    left: 0.4rem;
  }
}

.sectionItemText {
  flex: 1 1 auto;
  min-width: 0;
  padding: 1.6rem 0;
  word-break: break-word;

  @include utils.mq(max s) {
    padding: 1.4rem 0;
  }
}

.sectionItemNumber {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 7rem;
  text-align: center;
  font-weight: 700;
  border-left: 2px solid vars.$color-white;
}