/** --------------------------------------------------
*
*   EmployeeResidenceMap
*
*   TABLE OF CONTENTS
*   map
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/base/utils";


/** --------------------------------------------------
*   #map
*   -------------------------------------------------- */

.container {
  display: flex;
  flex-flow: row wrap;
  gap: 4rem;

  @include utils.mq(max l) {
    gap: 2rem;
  }
}

.mapBlock {
  flex: 1 1 auto;
  min-width: 0;
  display: flex;
  flex-flow: column nowrap;

  @include utils.mq(max m) {
    width: 100%;
  }

  & > * {
    width: 100%;
  }
}

.legendBlock {
  flex: 0 1 28rem;

  @include utils.mq(max m) {
    width: 100%;
    flex: 0 1 auto;
  }
}
