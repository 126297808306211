/** --------------------------------------------------
*
*   RoleEditor
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/base/utils";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.role {}

.roleChoice {
  display: flex;
  flex-flow: row wrap;

  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 0.2rem solid vars.$color-geyser;

  &>* {
    margin-right: 4rem;
  }
}

.roleInfo {
  font-size: 1.4rem;
}
