/** --------------------------------------------------
*
*   EmployeeResidenceTab
*
*   TABLE OF CONTENTS
*   general
*   header
*   content
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/cta";
@use "~/src/styles/mixins/layout";
@use "~/src/styles/mixins/text";
@use "~/src/styles/base/utils";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.container {}


/** --------------------------------------------------
*   #header
*   -------------------------------------------------- */

.header {
  margin-bottom: 2rem;
}

.headerInner {
  @include layout.contentWidth();
}

.headerTitle {}


/** --------------------------------------------------
*   #content
*   -------------------------------------------------- */

.content {}

.contentInner {
  @include layout.contentWidth();
}