/** --------------------------------------------------
*
*   SummaryTab
*
*   TABLE OF CONTENTS
*   info box
*   transportation
*   transportation: potential block
*   compare structure
*   compare item
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/cta";
@use "~/src/styles/mixins/layout";
@use "~/src/styles/mixins/text";
@use "~/src/styles/base/utils";


/** --------------------------------------------------
*   #info box
*   -------------------------------------------------- */

.infoBox {
  position: relative;
  background-color: vars.$color-black-squeeze;
  transform: translateY(50%);
}

.infoBoxInner {
  @include layout.contentWidth();
}

.infoBoxContainer {
  display: flex;
  align-items: flex-start;
  flex-flow: row nowrap;

  padding: 2.5rem 3rem;
  background-color: vars.$color-white;
  color: vars.$color-blue-zodiac;
  border: 0.1rem solid vars.$color-blue-zodiac;
  border-radius: 1rem;

  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: 600;

  transform: translateY(-50%);

  @include utils.mq(max m) {
    flex-flow: column wrap;
  }
}

.infoBoxIcon {
  flex: 0 0 auto;
  min-width: 0;
  width: 4rem;
  margin: 0.2rem 3rem 1rem 0;

  & svg {
    width: 4.3rem;
    height: 4.3rem;
  }
}

.infoBoxContent {
  flex: 1 1 auto;
  min-width: 0;

  & button {
    @include cta.buttonSkeleton();

    & svg {
      display: inline-block;
      vertical-align: middle;
      margin-right: 0.5rem;
    }

    & span + svg {
      margin-left: 0.5rem;
      margin-right: 0;
    }
  }

  & button,
  & a {
    color: vars.$color-matisse;
    text-decoration: underline;
    font-weight: inherit;
    line-height: inherit;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}




/** --------------------------------------------------
*   #transportation
*   -------------------------------------------------- */

.transportation {
  position: relative;
  padding-top: 5rem;
  background-color: vars.$color-black-squeeze;
  border-bottom: 0.2rem solid vars.$color-geyser;
}

.transportationInner {
  @include layout.contentWidth();
}

.transportationTypes {
  position: relative;
  z-index: 2;

  display: flex;
  flex-flow: row wrap;
}

.transportationType {
  flex: 0 1 33.33%;
  min-width: 0;

  position: relative;
  padding: 0 2rem 5.5rem 2rem;
  text-align: center;

  display: flex;
  flex-flow: column nowrap;

  // Seperator line (vertical)
  + .transportationType::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 0.2rem;
    background-color: vars.$color-geyser;

    // Seperator line (horizontal)
    @include utils.mq(max m) {
      bottom: auto;
      right: 0;
      width: 100%;
      height: 0.2rem;
    }
  }

  @include utils.mq(max m) {
    flex-basis: 100%;

    + .transportationType {
      padding-top: 5.5rem;
    }
  }
}

// Actions
.transporationActions {
  margin-bottom: 9rem;
  text-align: center;
}

.transporationActionsInner {
  @include layout.contentWidth();
}




/** --------------------------------------------------
*   #compare structure
*   -------------------------------------------------- */

.compareList {
  margin: 3rem 0 9rem;
}

.compareListInner {
  @include layout.contentWidth();
}

.compareListItems {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -1.2rem -2.4rem;
}

.compareItem {
  flex: 0 1 auto;
  min-width: 0;
  width: 50%;
  padding: 0 1.2rem 2.4rem;

  display: flex;
  flex-flow: column nowrap;

  @include utils.mq(max m) {
    width: 100%;
  }
}




/** --------------------------------------------------
*   #compare item
*   -------------------------------------------------- */

.compareItemInner {
  flex: 1 1 auto;
  width: 100%;
  padding: 4rem 5.5rem;
  background-color: vars.$color-blue-zodiac;
  color: vars.$color-white;
  border-radius: 1rem;

  display: flex;
  flex-flow: column nowrap;

  @include utils.mq(max s) {
    padding: 2rem;
  }
}

// Title
.compareItemTitle {
  width: 100%;
  margin-bottom: 1rem;
  font-size: 2rem;
}

// Content
.compareItemContent {
  width: 100%;
  margin-bottom: 4rem;
  font-size: 1.6rem;
  line-height: 1.75;

  @include utils.mq(max s) {
    font-size: 1.4rem;
  }
}

// Range
.compareItemRanges {
  width: 100%;
  margin-top: auto;
}

.compareItemRange {}

.compareItemRange + .compareItemRange {
  margin-top: 1.5rem;
}

.compareItemRangeTitle {
  margin-bottom: 1rem;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.14;
}

.rangeItem {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.rangeItemMin {
  flex: 0 1 12%;
  min-width: 5rem;

  padding-right: 1rem;
  font-weight: 600;
  font-size: 1.2rem;
}

.rangeItemCurrent {
  flex: 0 1 76%;
  min-width: 0;

  position: relative;
}

.rangeItemMax {
  flex: 0 1 12%;
  min-width: 5rem;

  padding-left: 1rem;
  font-weight: 600;
  font-size: 1.2rem;
  text-align: right;
}

.rangeValue {
  position: absolute;
  z-index: 10;
  left: 0; // See SummaryTab.tsx for the inline styling
  top: 0;
  transform: translateY(-50%);
  margin-left: -2.5rem;

  display: inline-flex;
  padding: 0.1rem 0.8rem;
  background-color: vars.$color-kournikova;
  color: vars.$color-blue-zodiac;
  border: 0.1rem solid transparent;
  border-radius: 5rem;
  font-weight: 600;
  font-size: 1.2rem;
}

.rangeCurrent {
  position: absolute;
  z-index: 5;
  left: 0;
  top: 0;
  transform: translateY(-50%);
  height: 0;
  width: 0; // See SummaryTab.tsx for the inline styling
  background-color: vars.$color-kournikova;
  border-top: 0.6rem solid vars.$color-kournikova;
  border-radius: 1rem;
}

.rangeMax {
  position: absolute;
  left: 0;
  top: 0;
  transform: translateY(-50%);
  height: 0.6rem;
  width: 100%;
  background-color: vars.$color-white;
  border: 0.1rem solid transparent;
  border-radius: 1rem;
}
