/** --------------------------------------------------
*
*   EnterpriseTile
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.enterprise {
  padding: 2.5rem 3rem;
  background-color: vars.$color-catskill-white;
  color: vars.$color-blue-zodiac;
  border: 0.1rem solid vars.$color-blue-zodiac;
  border-radius: 1rem;
}

.title {
  margin-bottom: 0.5rem;
}
