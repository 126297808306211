/** --------------------------------------------------
*
*   Tab.miniStyles
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/layout";
@use "~/src/styles/base/utils";
@use "~/src/styles/mixins/cta";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.miniTabsWrapper {
  margin-bottom: 4rem;

  & ul {
    display: flex;
    flex-flow: row nowrap;
    overflow-x: auto;
  }

  & li {
    flex: 0 0 auto;
    min-width: 0;

    display: flex;
    flex-flow: column nowrap;
  }

  & button {
    @include cta.buttonSkeleton;
    flex: 1 1 auto;
    width: 100%;
    display: block;
    padding: 0.6rem 2rem 0.6rem 2rem;

    font-size: 1.2rem;
    line-height: 1.3;
    font-weight: 600;
    text-decoration: none;
    text-align: center;

    border: 0.1rem solid vars.$color-brand;
    border-right: 0;

    &:hover,
    &:focus {
      background-color: vars.$color-background-light;
    }

    &:focus-visible,
    &:active {
      text-decoration: underline;
    }

    &:focus-visible {
      outline-offset: -0.2rem;
      box-shadow: inset 0 0 0 0.2rem white, inset 0 0 0 0.4rem black;
    }

    &[aria-current="true"] {
      background-color: vars.$color-kournikova;

      @include utils.mqset(highcontrast) {
        text-decoration: underline;
      }

      @include utils.mqset(forcedcolors-more) {
        text-decoration: underline;
      }
    }
  }

  & li:first-child button {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  & li:last-child button {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border-right: 0.1rem solid vars.$color-brand;
  }
}
