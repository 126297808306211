/** --------------------------------------------------
*
*   QuestionnaireForm
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/base/utils";

.questionnaireTitle {
  margin-bottom: 3rem;
  padding-bottom: 2rem;
  border-bottom: 0.2rem solid vars.$color-geyser;
}

.questionnaireOptions {
  margin-bottom: 4rem;
  padding-bottom: 4rem;
  border-bottom: 0.2rem solid vars.$color-geyser;
}

.questionnaireFormItem {
  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.questionnaireActions {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;

  > div {
    margin-bottom: 0;
  }

  button {
    width: 65%;

    @include utils.mq(max s) {
      width: 100%;
    }
  }

}

.policyFormItem {
  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.policyActions {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;

  a[data-displaytype=text] {
    margin-bottom: 0;

    & + button {
      width: 65%;

      @include utils.mq(max s) {
        width: 100%;
      }
    }
  }

  button {
    width: 100%;
  }
}