/** --------------------------------------------------
*
*   LoginAsEnterpriseButton
*
*   TABLE OF CONTENTS
*   button
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/cta";


/** --------------------------------------------------
*   #button
*   -------------------------------------------------- */

.loginAsEnterpriseButton {
  @include cta.ctaSecondary();

  padding: 0.7rem 3rem;
  border: 0;
}
