/** --------------------------------------------------
*
*   base - components - modal
*   MODAL
*
*   TABLE OF CONTENTS
*   general
*
*  -------------------------------------------------- */


@use "../../variables" as vars;


/** --------------------------------------------------
*  #general
*  -------------------------------------------------- */

// prevent body scroll when modal is open:

.ReactModal__Body--open {
  overflow: hidden;
  height: 100vh;
  position: relative;
}
