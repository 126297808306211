/** --------------------------------------------------
*
*   SwitchField
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/base/utils";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.switch {

  & legend {
    display: inline-block;
    margin-bottom: 0.8rem;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.5;
  }
}

.switchItems {
  display: flex;
  flex-flow: row wrap;

  overflow: hidden;
  border: 0.1rem solid vars.$color-blue-zodiac;
  border-radius: 1rem;
}

.switchItem {
  flex: 1 1 auto;

  position: relative;
  border-right: 0.1rem solid vars.$color-blue-zodiac;
  text-align: center;

  &:last-child {
    border-right: none;
  }

  /* input */
  /* ***** */

  /*
   * the actual radiobutton input is hidden
   * -> do not use "display: none" or
   *    "visibility: hidden" to hide the element,
   *    because that will make it inaccessible
   *    for keyboard users
   */

  & > input {
    appearance: none;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    border: none;
  }

  & > label {
    position: relative;
    z-index: 2;
    display: block;
    padding: 1.5rem 2rem;
    background-color: vars.$color-input-background;
    font-weight: 600;
    font-size: 1.4rem;
    cursor: pointer;
  }

  /* checked */
  /* ******* */

  & > input:checked + label {
    background-color: vars.$color-kournikova;
    text-decoration: underline;
  }

  /* focus */
  /* ***** */

  & > input:focus-visible + label {
    outline: 0.2rem solid black;
    outline-offset: -0.6rem;
  }

  /* hover */
  /* ***** */

  & > input:hover + label {
    background-color: vars.$color-kournikova;
  }
}
