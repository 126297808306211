/** --------------------------------------------------
*
*   EnterpriseOverview
*
*   TABLE OF CONTENTS
*   intro
*   overview
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/layout";
@use "~/src/styles/mixins/text";
@use "~/src/styles/base/utils";


/** --------------------------------------------------
*   #intro
*   -------------------------------------------------- */

.intro {
  margin-top: vars.$content-spacing-vertical;
  margin-bottom: vars.$content-spacing-vertical;
}

.introInner {
  @include layout.contentWidth();
}

.pageTitle {
  @include text.pageTitle();
}




/** --------------------------------------------------
*   #overview
*   -------------------------------------------------- */

.overview {
  margin-top: vars.$content-spacing-vertical;
  margin-bottom: vars.$content-spacing-vertical;
}

.overviewInner {
  @include layout.contentWidth();
}

/* search block */
/* ************ */

.searchBlock {
  margin-bottom: 4rem;
}

/* results block */
/* ************* */

.resultBlock {}
