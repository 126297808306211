/** --------------------------------------------------
*
*   Footer
*
*   TABLE OF CONTENTS
*   general
*   navigation
*   brand logo floater
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/base/utils";
@use "~/src/styles/mixins/cta";
@use "~/src/styles/mixins/layout";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.footer {
  margin-top: auto; // See globals.scss for the other part of margin-top: auto; trick

  position: relative;
  padding: 4.8rem 0;
  border-top: 0.2rem solid vars.$color-geyser;
  background-color: vars.$color-background-light;

  @include utils.mq(max s) {
    padding-right: 0;
    padding-bottom: 14rem;
  }

  @media print {
    display: none;
  }
}

.footerInner {
  @include layout.contentWidth();
}

.container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding-right: 20rem;

  font-size: 1.2rem;
  line-height: 1.5;
  font-weight: 600;

  @include utils.mq(max m) {
    display: block;
    padding-right: 0;
  }
}




/** --------------------------------------------------
*   #navigation
*   -------------------------------------------------- */

.nav {
  position: relative;
  display: flex;
  flex-flow: row wrap;

  font-size: 1.2rem;
  line-height: 1.5;

  @include utils.mq(max m) {
    display: block;
  }
}

.list {
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  @include utils.mq(max m) {
    display: block;
  }
}

.item {
  padding: 0.5rem 0;

  @include utils.mq(max m) {
    padding: 1rem 0;
  }

  & button {
    @include cta.buttonSkeleton();
    font-weight: 600;
  }

  & a,
  & button {

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  &:not(:last-child)::after {
    content: '-';
    margin-right: 0.4rem;
    margin-left: 0.4rem;

    @include utils.mq(max m) {
      display: none;
    }
  }
}

.makeitflyLink {
  display: flex;
  flex-flow: row wrap;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  & span {
    margin-right: 0.4rem;
  }

  & svg {
    width: 7.7rem;
    height: 1.6rem;
    margin-top: -0.3rem;
  }
}




/** --------------------------------------------------
*   #brand logo floater
*   -------------------------------------------------- */

.brandLogoFloater {
  width: 14.3rem;
  height: 12.3rem;
  position: absolute;
  z-index: 3;
  right: 0;
  bottom: 0;

  @include utils.mq(max s) {
    top: auto;
    bottom: 0;
  }

  & img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: right bottom;
  }
}
