/** --------------------------------------------------
*
*   ForgotPasswordPage
*
*   TABLE OF CONTENTS
*   page intro
*
*   -------------------------------------------------- */


@use "~/src/styles/mixins/text";


/** --------------------------------------------------
*   #page intro
*   -------------------------------------------------- */

.pageTitle {
  @include text.pageTitle();
}
