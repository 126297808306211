/** --------------------------------------------------
*
*   components - forms
*   REACT SELECT
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "../../variables" as vars;
@use "../../base/utils";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.react-select-container {
  position: relative;
  width: 100%;

  font-family: vars.$font-basic;
  font-size: 1.6rem;
  line-height: 1.5;
  color: vars.$color-input-foreground;
  cursor: pointer;
  transition: border-color 0.15s ease-out;

  & .react-select-icon {
    position: absolute;
    top: 50%;
    right: 3rem;
    transform: translateY(-50%);
    pointer-events: none;
  }

  & .react-select__organization {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;

    padding: 1.4rem 2.6rem;
  }

  & .react-select__organization__info {
    padding-right: 2rem;

    @include utils.mq(max m) {
      display: block;
      width: 100%;
    }
  }

  & .react-select__organization__name {
    display: block;
    font-weight: 600;
  }

  & .react-select__organization__address {
    display: block;
    font-size: 1.4rem;
    white-space: normal;
  }

  & .react-select__organization__number {
    font-size: 1.4rem;

    @include utils.mq(max m) {
      display: block;
      width: 100%;
    }
  }

  & .react-select__control {
    padding-right: 5rem;
    background-color: vars.$color-input-background;
    border: 0.1rem solid vars.$color-input-border;
    border-radius: 1rem;
    cursor: pointer;

    &:hover {
      border-color: vars.$color-input-border;
    }

    &:focus-within {
      border-color: vars.$color-brand;
      box-shadow: 0 0 0 0.1rem black;
      outline: 0.1rem solid transparent;
      outline-offset: 0.2rem;
    }
  }

  & .react-select__value-container {
    padding: 0;
  }

  & .react-select__input-container {
    margin: 0;
    padding: 1.4rem 2.6rem;
    font-weight: 600;
  }

  & .react-select__input[type='text'] {
    &:focus {
      box-shadow: none;
    }
  }

  & .react-select__menu {
    overflow: hidden;
    border: 0.1rem solid vars.$color-brand;
    border-radius: 1rem;
    box-shadow: none;
  }

  & .react-select__menu-list {
    padding: 0;
  }

  & .react-select__option {
    padding: 0 5rem 0 0;
    cursor: pointer;
  }

  & .react-select__option + .react-select__option {
    border-top: 0.1rem solid transparent;
  }

  & .react-select__option--is-disabled {
    display: none;
  }

  & .react-select__option--is-focused {
    background-color: vars.$color-kournikova;
    color: vars.$color-brand;
  }

  & .react-select__option--is-selected {
    display: block;

    background-color: vars.$color-catskill-white;
    color: vars.$color-brand;
    opacity: 0.5;
  }

  & .react-select__indicators {
    display: none;
  }

  .react-select-container-placeholder {
    padding: 1.4rem 2.6rem;
  }
}