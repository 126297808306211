/** --------------------------------------------------
*
*   Checkbox
*
*   TABLE OF CONTENTS
*   general
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/base/utils";


/** --------------------------------------------------
*   #general
*   -------------------------------------------------- */

.checkbox {
  position: relative;
  min-height: 2.8rem;
  margin-bottom: 0.25rem;

  /* input */
  /* ***** */

  &>input {
    appearance: none;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 2.8rem;
    height: 2.8rem;
    border: none;
    background: transparent;
    margin: 0;
    padding: 0;
  }

  /* label */
  /* ***** */

  &>label {
    display: inline-block;
    position: relative;
    margin: 0;
    padding: 0.1rem 0 0.1rem 4.2rem;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.5;
    color: vars.$color-blue-zodiac;
    cursor: pointer;

    /*
     * pseudo-elements for the checkbox-container and the
     * checkbox-image
     */

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
    }

    &::before {
      top: 0;
      left: 0;
      width: 2.8rem;
      height: 2.8rem;
      border: 0.2rem solid vars.$color-blue-zodiac;
      border-radius: 0.5rem;
      background-color: vars.$color-white;
      transition: background-color 0.15s ease, border-color 0.15s ease;
    }

    &::after {
      top: 0.7rem;
      left: 0.6rem;
      width: 1.5rem;
      height: 0.9rem;
      transform: rotate(-45deg);
      border: solid white;
      border-width: 0 0 0.2rem 0.2rem;
      border-top-color: transparent;
      background: transparent;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.2s ease, visibility 0.2s ease;
    }

    /* don't allow links in label: */
    & a {
      display: none;
    }
  }

  /* focus + checked */
  /* *************** */

  &>input:focus+label {
    &::before {
      box-shadow: 0 0 0 0.2rem white, 0 0 0 0.4rem black;
    }
  }

  &>input:checked+label {
    &::before {
      background-color: vars.$color-blue-zodiac;
      border-color: vars.$color-blue-zodiac;
    }

    &::after {
      opacity: 1;
      visibility: visible;
    }
  }

  /* disabled */
  /* ******** */

  &>input[disabled]+label {
    opacity: 0.6;
    cursor: not-allowed;
  }
}
