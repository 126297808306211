/** --------------------------------------------------
*
*   MapFilterLegend
*
*   TABLE OF CONTENTS
*   legend
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;


/** --------------------------------------------------
*   #legend
*   -------------------------------------------------- */

.MapFilterLegend {
  background: vars.$color-white;
  border-radius: 1rem;
  border: 0.1rem solid vars.$color-blue-zodiac;
}

.MapFilterLegendSection {
  padding: 1.5rem 2rem;
  border-bottom: 0.2rem solid rgba(vars.$color-blue-zodiac, 0.15);

  &:last-child {
    border: 0;
  }
}

.MapFilterLegendMarker {
  background-color: rgba(vars.$color-kournikova, 0.3);
  width: 4.7rem;
  height: 4.7rem;
  margin-bottom: 1rem;
  border: 0.1rem solid transparent;
  border-radius: 50%;
  position: relative;

  &::before {
    content: '';
    background-color: rgba(vars.$color-kournikova, 1);
    width: 2.7rem;
    height: 2.7rem;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50%), calc(-50% - 0.1rem));
  }
}

.MapFilterLegendTitle {
  margin: 0.5rem 0 0;
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: 800;
}

.MapFilterLegendSectionLabel {
  display: block;
  margin-bottom: 1rem;
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: 600;
  background: red;
}

.potentialLabel {
  padding-left: 2.2rem;
  position: relative;
}

.potentialIcon {
  content: '';
  display: block;
  width: 1rem;
  border-left: 0.7rem solid transparent;
  border-right: 0.7rem solid transparent;
  border-bottom: 1.4rem solid gray;
  position: absolute;
  top: 0;
  left: 0;
}