/** --------------------------------------------------
*
*   MobiscanList
*
*   TABLE OF CONTENTS
*   scanlist
*   item
*   item: info block
*   item: status block
*   item: status block: status display
*   item: status block: summary display
*   item: status block: actions
*   empty list
*
*   -------------------------------------------------- */


@use "~/src/styles/variables" as vars;
@use "~/src/styles/mixins/layout";
@use "~/src/styles/mixins/text";
@use "~/src/styles/base/utils";


/** --------------------------------------------------
*   #scanlist
*   -------------------------------------------------- */

.scanList {
  list-style: none;
}

.scanList > li {}

.scanList > li + li {
  border-top: 0.2rem solid vars.$color-geyser;
}




/** --------------------------------------------------
*   #item
*   -------------------------------------------------- */

.item {
  background-color: vars.$color-white;
  padding: 4rem 0;
  position: relative;
  transition: background-color 0.3s;
}

.item[data-status="draft"] {
  &:hover {
    background-color: vars.$color-background-light;

    .itemStatusDisplay {
      background-color: vars.$color-white;
    }
  }
}

.item[data-status="completed"] {
  background-color: vars.$color-background-light;

  &:hover {
    background-color: vars.$color-geyser;
  }
}

.itemInner {
  @include layout.contentWidth();
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

/* info block */
/* ********** */

.itemInfoBlock {
  flex: 0 1 auto;
  min-width: 0;
  width: 40%;
  padding-right: 5%;

  @include utils.mq(max m) {
    width: 100%;
    padding-right: 0;
    margin-bottom: 3.2rem;
  }
}

/* status block */
/* ************ */

.itemStatusBlock {
  flex: 1 1 auto;
  min-width: 0;
  width: 50%;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  @include utils.mq(max m) {
    width: 100%;
  }
}




/** --------------------------------------------------
*   #item: info block
*   -------------------------------------------------- */

.itemTitle {
  @include text.contentTitle();
}

.itemAddress {
  margin: 0.2rem 0 1.6rem;
  font-size: 1.4rem;
  line-height: 1.3;
}

.itemDate {
  @include text.contentTitle();
  color: vars.$color-text--light;
}

.itemRequester {
  margin-top: 1.6rem;

  font-size: 1.2rem;
  line-height: 1.3;
  font-weight: bold;
  text-transform: uppercase;
}




/** --------------------------------------------------
*   #item: status block
*   -------------------------------------------------- */

.itemStatusDetails {
  flex: 1 1 auto;
  min-width: 0;
}

.itemStatusActions {
  flex: 0 0 auto;
  min-width: 0;
  margin-left: 10%;

  display: flex;
  flex-flow: column wrap;
  gap: 1rem;
}




/** --------------------------------------------------
*   #item: status block: status display
*   -------------------------------------------------- */

/*
* "status block" is displayed when the item
* status is not yet "completed"
*/

.itemStatusDisplay {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  max-width: 43.6rem;
  border-radius: 8rem;
  padding: 1.6rem 3.8rem;
  font-size: 1.4rem;
  line-height: 1.4;
  background-color: vars.$color-background-light;
  color: vars.$color-text--bright;
  transition: background-color 0.3s;

  @include utils.mq(max m) {
    max-width: 100%;
  }

  @include utils.mq(max s) {
    flex-flow: row wrap;
    border-radius: 1rem;
    padding: 2rem;
  }
}

.itemStatusDisplayIcon {
  flex: 0 0 auto;
  min-width: 0;
  width: 4rem;
  margin-right: 2.8rem;

  @include utils.mq(max s) {
    width: 100%;
    margin-bottom: 1rem;
  }

  & svg {
    width: 100%;
    height: auto;
  }
}

.item[data-status="in-progress"] .itemStatusDisplayIcon {

  & .statusSpinnerMovement {
    display: none;

    @include utils.mqset(motion) {
      display: none;
    }
  }

  & .statusSpinnerStatic {
    display: block;

    @include utils.mqset(motion) {
      display: block;
    }
  }
}

.itemStatusDisplayText {
  flex: 1 1 auto;
  min-width: 0;
}

.itemStatusDisplaySubtitle {
  white-space: pre-line;
}




/** --------------------------------------------------
*   #item: status block: summary display
*   -------------------------------------------------- */

/*
* "summary" is displayed when the item status is "completed"
*/

.itemSummary {
  position: relative;
  background-color: vars.$color-white;
  padding: 2rem;
  border-radius: 15rem;
  max-width: 43.6rem;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  @include utils.mq(max m) {
    max-width: 100%;
  }

  @include utils.mq(max s) {
    border-radius: 1rem;
    flex-flow: column wrap;
  }

  // decoration to go between the two circles:
  &::after {
    content: '';
    display: block;
    width: 28%;
    height: 100%;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 35%;
    background-image: url('../../../../assets/images/decoration_movement-line.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;

    @include utils.mq(max s) {
      display: none;
    }
  }
}

.itemSummaryBlock {
  position: relative;
  width: 38%;

  @include utils.mq(max s) {
    width: 100%;
  }
}

.itemSummaryBlock + .itemSummaryBlock {

  @include utils.mq(max s) {
    padding-top: 1rem;
  }
}

/* circle layout */
/* ************* */

.itemSummaryCircle {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  /* keep aspect ratio: */
  &::before {
    content: "";
    float: left;
    padding-bottom: 100%;
  }

  &::after {
    clear: left;
    content: " ";
    display: table;
  }

  @include utils.mq(max s) {

    &::before,
    &::after {
      display: none;
    }
  }
}

// use an absolutely positioned background
// element to prevent elongated circles, but
// allow elongated circles if there's too much
// content:
.itemSummaryCircleBackground {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: vars.$color-background-light;
  border: 0.1rem solid vars.$color-brand;
  border-radius: 50%;

  @include utils.mq(max s) {
    border-radius: 1rem;
  }
}

.itemSummaryCircle[data-type="potential"] .itemSummaryCircleBackground {
  background-color: vars.$color-kournikova;
}

.itemSummaryCircleContent {
  position: relative;
  z-index: 2;
  text-align: center;

  @include utils.mq(max s) {
    padding: 1rem;
  }
}

.itemSummaryCircleValue {
  font-weight: bold;
  font-size: 3.6rem;
  line-height: 1;
  padding-top: 0.5rem;

  @include utils.mq(max l) {
    font-size: 2.8rem;
  }

  @include utils.mq(max m) {
    font-size: 2.4rem;
  }
}

.itemSummaryCircleDescription {
  padding-top: 0.6rem;
  font-size: 1.2rem;
  line-height: 1.1;
  font-weight: 600;
  max-width: 60%;
  margin: 0 auto;

  @include utils.mq(max l) {
    font-size: 1rem;
  }

  @include utils.mq(max s) {
    max-width: 100%;
  }
}




/** --------------------------------------------------
*   #item: status block: actions
*   -------------------------------------------------- */

.itemAction {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 0.8rem 1.6rem;
  color: vars.$color-brand;
  text-decoration: none;
  font-weight: 600;
  border: 0.1rem solid;
  border-radius: 1rem;
  background-color: vars.$color-background-light;
  transition: background-color 0.15s ease-out;

  &:hover,
  &:focus {
    background-color: vars.$color-white;
  }

  // make the element take up space, even if it is not visible:
  &[aria-hidden="true"] {
    visibility: hidden;
  }

  & svg {
    width: 2.2rem;
    height: 2.2rem;
  }

  &:active svg {
    transform: scale(0.9);
  }
}

.itemDelete {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 0.8rem 1.6rem;
  color: vars.$color-monza;
  text-decoration: none;
  font-weight: 600;
  border: 0.1rem solid;
  border-color: vars.$color-monza;
  border-radius: 1rem;
  background-color: vars.$color-background-light;
  transition: background-color 0.15s ease-out;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: vars.$color-white;
  }

  // make the element take up space, even if it is not visible:
  &[aria-hidden="true"] {
    visibility: hidden;
  }

  & svg {
    width: 2.2rem;
    height: 2.2rem;
  }

  &:active svg {
    transform: scale(0.9);
  }
}




/** --------------------------------------------------
*   #empty list
*   -------------------------------------------------- */

.emptyNotice {
  @include layout.contentWidth();
  margin-bottom: 4rem;
}

.emptyNoticeInner {
  background-color: vars.$color-background-light;
  border-radius: 0.5rem;
  padding: 2rem 3.4rem;
  text-align: center;
}